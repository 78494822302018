import {
  CustomerPropertySearchByIdQueryQuery,
  CustomerSearchHistoryQueryQuery,
  DateInput,
  MapPropertySearchByLatLongQuery,
  PropertyDateRangeInput,
} from "__generated__/typedefs";
import {
  HistoryPropertyDetailsItem,
  NewPriceItems,
  RecentlyViewedHotel,
} from "src/components/flexComponents/RecentlyViewedHotels/typings";
import { Logger, NOOP_LOGGER } from "bernie-logger";
import { action, computed, makeObservable, observable } from "mobx";

import { CustomerPropertySearchByIdResponse } from "components/flexComponents/RecentlyViewedHotels/typings";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { MapPlace } from "typings/map/eg-map";
import { SerializedData } from "bernie-core";
import { Store } from "bernie-plugin-mobx";
import { formatText } from "bernie-l10n";
import { getNextFriday } from "src/components/utility/DateUtils";

type StartDate = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<NonNullable<CustomerSearchHistoryQueryQuery["customer"]>["searchHistory"]>[number]
      >["items"]
    >[number]
  >["startDate"]
>;

type EndDate = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<NonNullable<CustomerSearchHistoryQueryQuery["customer"]>["searchHistory"]>[number]
      >["items"]
    >[number]
  >["endDate"]
>;

type Options = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<NonNullable<CustomerPropertySearchByIdQueryQuery["propertySearch"]>["properties"]>[number]
      >["price"]
    >["options"]
  >[number]
>;

export enum VariationType {
  PRICE_INCREASE = "increase",
  PRICE_DECREASE = "decrease",
  AVAILABLE = "available",
  SOLD_OUT = "sold_out",
}

export class HotelsStore extends Store {
  public filteredHotels: Hotel[] | null = null;
  public hasOverfilteredMessage: Boolean = false;
  public allHotelsOnMap: MapPlace[] = [];
  public isLoading = false;
  public isLoadingFilterHotels = false;
  public hasMapErrorMessage = false;
  public allHotelsOnMapLatLong: MapPlace[] = [];

  /* istanbul ignore next */
  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);

    makeObservable(this, {
      filteredHotels: observable,
      hasOverfilteredMessage: observable,
      allHotelsOnMap: observable,
      isLoading: observable,
      isLoadingFilterHotels: observable,
      hasMapErrorMessage: observable,
      hotelsOnMap: computed,
      setAllHotelsOnMapOnHotelsByLatLongSuccessResponse: action,
      setAllHotelsOnMapOnFlexHotels: action,
    });
  }

  /**
   * Set recently viewed hotels and hotels
   * Add infositeURL and newSearchPrice to recentViewedHotel object
   * newSearchPrice is the hotel price and originalSearchPrice is the recently view hotel price
   * @param {RecentlyViewedHotel[]} recentlyViewedHotels - Array of recently hotels viewed by the user
   * @param {CustomerPropertySearchByIdQuery.Properties} freshHotelData - Hotel that match with the recently view hotel
   * @memberof HotelsStore
   */
  public getFreshRecentlyViewedHotels = (
    recentlyViewedHotels: HistoryPropertyDetailsItem[],
    freshHotelData: CustomerPropertySearchByIdResponse
  ): RecentlyViewedHotel[] => {
    // facilitate our lives by building map of fresh hotel data with id-startDate-endDate key
    const buildDateKey = (date: StartDate | EndDate | DateInput) => `${date.year}-${date.month}-${date.day}`;
    const buildKey = (
      id: string,
      checkInDate: StartDate | EndDate | DateInput,
      checkOutDate: StartDate | EndDate | DateInput
    ) => `${id}-${buildDateKey(checkInDate)}-${buildDateKey(checkOutDate)}`;

    const { propertySearch } = freshHotelData;

    if (!propertySearch) {
      return [];
    }

    const mapOfFreshHotelData: any = propertySearch.properties.reduce(
      (acc2, property) => ({
        ...acc2,
        [buildKey(
          property.id,
          propertySearch.searchCriteria.dateRange.checkInDate,
          propertySearch.searchCriteria.dateRange.checkOutDate
        )]: property,
      }),
      {}
    );

    const getFreshHotel = (recentlyViewedHotel: HistoryPropertyDetailsItem) =>
      mapOfFreshHotelData[
        buildKey(
          recentlyViewedHotel.productInfo?.propertyId || "",
          recentlyViewedHotel.startDate,
          recentlyViewedHotel.endDate
        )
      ];

    return recentlyViewedHotels.filter(getFreshHotel).map((recentlyViewedHotel) => {
      // get fresh hotel
      const freshHotel = getFreshHotel(recentlyViewedHotel);

      // check for price variation
      const firstPriceOption = freshHotel.price?.options && freshHotel.price?.options[0];
      const priceVariation = this.getPriceVariation(recentlyViewedHotel, firstPriceOption);

      return {
        ...recentlyViewedHotel,
        ...priceVariation,
        hisUrl: freshHotel.infositeURL,
      };
    });
  };

  /**
   * Add a new property to recently viewed hotel's object regarding price variation
   */
  private getPriceVariation = (
    hotelFromCustomerSearchHistory: HistoryPropertyDetailsItem & NewPriceItems,
    updatedPrice?: Options | null
  ): NewPriceItems => {
    const { originalSearchPrice } = hotelFromCustomerSearchHistory;
    const originalPrice = originalSearchPrice ? Math.round(originalSearchPrice.amount) : 0;
    const currentPrice = updatedPrice?.displayPrice ? Math.round(updatedPrice.displayPrice.amount) : 0;

    // if no new pricing then it is sold out
    if (!currentPrice) {
      return {
        priceChange: VariationType.SOLD_OUT,
      };
    }

    const newPriceItem: NewPriceItems = {};

    // Is price decreased
    if (originalPrice > currentPrice) {
      newPriceItem.priceChange = VariationType.PRICE_DECREASE;
    }

    // Is increased
    if (originalPrice < currentPrice) {
      newPriceItem.priceChange = VariationType.PRICE_INCREASE;
    }

    // Is now available
    if (!originalPrice && currentPrice) {
      newPriceItem.priceChange = VariationType.AVAILABLE;
    }

    // Set new price
    if (currentPrice) {
      newPriceItem.newPrice = currentPrice;
      newPriceItem.newPriceFormatted = updatedPrice?.displayPrice?.formatted;
    }

    return newPriceItem;
  };

  /**
   * Fetch an array of hotels for a given latitude and longitude. Queries the
   * BEX API and reassigns the store's allHotelsOnMaps and isLoading property
   * and returns it.
   * @param {ExtendedContextStore} contextStore - Context to be passed to getBextApiContext
   * @param {number} lat - Latitude on the map that the user is looking at
   * @param {number} lng - Longitude on the map that the user is looking at
   * @returns {Promise<PropertyForMap[]>} - Array of found hotels. Defaults to empty array in case of errors
   * @memberof HotelsStore
   */
  public setAllHotelsOnMapOnHotelsByLatLongSuccessResponse(
    propertySearch: MapPropertySearchByLatLongQuery["propertySearch"],
    locationId: string,
    searchDaysOffset = 0
  ) {
    const newHotels: MapPlace[] = [];

    // Check if the hotel/property has all the necessary info to be displayed on the map
    for (const property of propertySearch.properties) {
      if (property.mapMarker && property.mapMarker.latLong && property.infositeURL) {
        const priceAmount = property.price?.lead?.amount;
        const newHotel: MapPlace = {
          id: property.id,
          placeName: property.name,
          formattedPrice:
            property.price && property.price.lead && property.price.lead.amount
              ? property.price.lead.formatted
              : undefined,
          latitude: property.mapMarker.latLong.latitude,
          longitude: property.mapMarker.latLong.longitude,
          overallReviewRating: formatText("maps.sheets.hotel.reviewRating.text", property.reviews.score),
          reviewCount: `(${formatText(
            "maps.sheets.hotel.reviews.text",
            property.reviews.total,
            property.reviews.total
          )})`,
          localizedSubtitle: property.reviews.localizedSubtitle,
          imageUrl: property.image && property.image.url,
          hrefUrl: this.constructPinnedHSRUrl(locationId, property.id, searchDaysOffset),
          icon: {
            type: priceAmount ? "price" : "soldOut",
          },
        };

        newHotels.push(newHotel);
      }
    }

    // Replace existing properties
    this.allHotelsOnMapLatLong = newHotels;
  }

  /**
   * Getter for allHotelsOnMap. Filters through them to prevent the bug on BEX
   * API/our data where we sometimes have hotels on in the middle of the ocean
   * (0,0).
   * @readonly
   * @type {MapPlace[]}
   */
  public get hotelsOnMap(): MapPlace[] {
    return this.allHotelsOnMap.filter((hotel: MapPlace) => hotel.latitude !== 0 || hotel.longitude !== 0);
  }

  public setAllHotelsOnMapOnFlexHotels(hotels: Hotel[], contextStore: ExtendedContextStore, locationId: string) {
    const language = contextStore.locale.split(/[_-]/);

    const newHotels: MapPlace[] = hotels.reduce(
      (acc, newHotel) => {
        // Do not add duplicated hotels
        if (acc.find((mapPlace) => mapPlace.id === newHotel.hotelId)) {
          return acc;
        }

        let overallReviewRating;
        if (newHotel.hotelOverallReviewRating) {
          overallReviewRating = formatText(
            "maps.sheets.hotel.reviewRating.text",
            newHotel.hotelOverallReviewRating.toLocaleString(language, { maximumFractionDigits: 1 })
          );
        }

        let reviewCount;
        if (newHotel.reviewCount) {
          reviewCount = `(${formatText(
            "maps.sheets.hotel.reviews.text",
            newHotel.reviewCount,
            newHotel.reviewCount.toLocaleString(language, { maximumFractionDigits: 1 })
          )})`;
        }
        return [
          ...acc,
          {
            id: newHotel.hotelId,
            placeName: newHotel.hotelName,
            formattedPrice: newHotel.formattedPrice,
            latitude: newHotel.hotelLatitude,
            longitude: newHotel.hotelLongitude,
            overallReviewRating,
            reviewCount,
            imageUrl: newHotel.hotelImageUrl,
            hrefUrl: this.constructPinnedHSRUrl(locationId, newHotel.hotelId),
            infoSiteUrl: newHotel.datedHotelInfositeUrl,
            icon: {
              type: newHotel.formattedPrice ? "price" : "soldOut",
            },
          },
        ];
      },
      [...this.allHotelsOnMap]
    );

    this.allHotelsOnMap = newHotels;
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  /**
   * Calculate and return search date range for hotels we want to find around
   * our user. We choose check-in on a Friday two weeks from now. Note that the
   * range is not based on what the user input in the Wizard, so it's not dynamic
   * @returns {PropertyDateRangeInput} - Check in date and check out dates
   */
  public getSearchDateRange(searchDaysOffset = 0): PropertyDateRangeInput {
    //Check-in is Friday two weeks from now
    //Check out is the day after that
    const today = new Date();
    let checkInDate = new Date();
    if (searchDaysOffset > 0) {
      //If search offset is given use it
      checkInDate.setDate(today.getDate() + searchDaysOffset);
    } else {
      //If no search offset next friday
      checkInDate = getNextFriday(today);
    }
    const checkOutDate = new Date(checkInDate);
    checkOutDate.setDate(checkInDate.getDate() + 1);

    return {
      checkInDate: {
        day: checkInDate.getDate(),
        month: checkInDate.getMonth() + 1,
        year: checkInDate.getFullYear(),
      },
      checkOutDate: {
        day: checkOutDate.getDate(),
        month: checkOutDate.getMonth() + 1,
        year: checkOutDate.getFullYear(),
      },
    };
  }

  public constructPinnedHSRUrl(regionId: string, hotelId: string, searchDaysOffset = 0) {
    const searchDateRange: PropertyDateRangeInput = this.getSearchDateRange(searchDaysOffset);
    const startDate = `${searchDateRange.checkInDate.year}-${searchDateRange.checkInDate.month}-${searchDateRange.checkInDate.day}`;
    const endDate = `${searchDateRange.checkOutDate.year}-${searchDateRange.checkOutDate.month}-${searchDateRange.checkOutDate.day}`;
    return `/Hotel-Search?selected=${hotelId}&PinnedHotelID=${hotelId}&HadPinnedHotel=true&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`;
  }

  public formatDate(startDate: DateInput, endDate: DateInput): PropertyDateRangeInput {
    return {
      checkInDate: {
        day: startDate.day,
        month: startDate.month,
        year: startDate.year,
      },
      checkOutDate: {
        day: endDate.day,
        month: endDate.month,
        year: endDate.year,
      },
    };
  }
}

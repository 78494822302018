// /stores/index.ts
import { AnalyticsStore, PageStore, ExperimentStore } from "bernie-plugin-mobx";
import { AdvertisingStore } from "bernie-plugin-ads-loader-script";
import { inject, IWrappedComponent } from "mobx-react";

import { FlexViewModelStore } from "./flexViewModel/FlexViewModelStore";
import { MarkdownStore } from "./markdownHandler/typings";
import { UIPageStore } from "./UIPageStore";
import { CookiesStore } from "./CookiesStore";
import { HotelsStore } from "./HotelsStore";
import { StaticMapStore } from "./staticMap/StaticMapStore";
import { DXStore } from "./DXStore";
import { DestinationSuggestionsStore } from "./DestinationSuggestionsStore";
import { CompositionStore } from "./CompositionStore";
import { WizardStore } from "./wizard/WizardStore";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { PackagePillStore } from "./PackagePillStore";
import { FlexModuleModelStore } from "./FlexModuleModelStore";
import { HotwireConfigStore } from "stores/HotwireConfigStore";
import { PropertyFiltersStore } from "./PropertyFiltersStore";
import { FlightsStore } from "stores/FlightsStore";
import UriContextStore from "./UriContextStore/UriContextStore";
import { ExperienceTemplateStore } from "stores/ExperienceTemplateStore";
import { ClientChatbotStore } from "./chatbotStore/ClientChatbotStore";

// Add to this list whenever a new store is added.
export interface StoresType {
  analytics: AnalyticsStore;
  chatbot: ClientChatbotStore;
  flexViewModel: FlexViewModelStore;
  uiPage: UIPageStore;
  cookies: CookiesStore;
  markdown: MarkdownStore;
  hotels: HotelsStore;
  staticMap: StaticMapStore;
  dx: DXStore;
  advertising: AdvertisingStore;
  destinationSuggestions: DestinationSuggestionsStore;
  wizardState: WizardStore;
  compositionStore: CompositionStore;
  context: ExtendedContextStore;
  packagePillStore: PackagePillStore;
  flexModuleModelStore: FlexModuleModelStore;
  hotwireConfig: HotwireConfigStore;
  propertyFilters: PropertyFiltersStore;
  page: PageStore;
  flightsStore: FlightsStore;
  experiment: ExperimentStore;
  uriContext: UriContextStore;
  experienceTemplateStore: ExperienceTemplateStore;
}

/**
 * Use this instead of {@code @inject} so you will not have to provide stores in
 * tests or use the non-null assertion operator (!) and ensure strong typing.
 *
 * @example
 * interface ExampleProps { name: string; analytics: AnalyticsStore; }
 * const Example = withStores("analytics") = (props: ExampleProps) => {...}
 */
export const withStores = <TStoreProps extends keyof StoresType>(...stores: TStoreProps[]) => <
  TProps extends Pick<StoresType, TStoreProps>
>(
  component: React.ComponentType<TProps>
) => {
  return inject(...stores)(component) as InjectedComponent<TStoreProps, TProps>;
};

type InjectedComponent<TStoreProps extends keyof StoresType, TProps extends Pick<StoresType, TStoreProps>> = React.FC<
  Omit<TProps, keyof Pick<StoresType, TStoreProps>> & Partial<Pick<StoresType, TStoreProps>>
> &
  IWrappedComponent<TProps>;

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

export const getQueryParamValue: (queryParamKey: string) => string | undefined = (queryParamKey: string) => {
  if (typeof window === "undefined") {
    return;
  }

  const query = location?.search || "";
  query.replace("?", "");
  const queryParams = query.split("&");
  const keyValue = queryParams.find((param) => param.includes(queryParamKey));

  return keyValue && keyValue.split("=")[1];
};

/**
 * Isomorphically checks if a particular query param key-value pair exists in the URL based on the context and returns a boolean.
 *
 * @param {string} queryKey
 * @param {string} queryValue
 * @param {ExtendedContextStore} context
 * @returns {boolean}
 */
export const hasQueryParamValue = (queryKey: string, queryValue: string, context?: ExtendedContextStore): boolean => {
  if (!context || !context.queryParams || Object.keys(context.queryParams).length === 0 || !queryKey || !queryValue) {
    return false;
  }

  return (context.queryParams[queryKey] && context.queryParams[queryKey] === queryValue) || false;
};

export const updateQueryStringParameter = (uri: string, key: string, value: string | null) => {
  const re = new RegExp(`([?&])${key}=.*?(&|#|$)`, "i");

  if (value === null) {
    if (uri.match(re)) {
      return uri.replace(re, "$1$2");
    }

    return uri;
  }
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }
  let hash = "";
  if (uri.indexOf("#") !== -1) {
    hash = uri.replace(/.*#/, "#");
    uri = uri.replace(/#.*/, "");
  }
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";

  return `${uri}${separator}${key}=${value}${hash}`;
};

export const buildReferrer = (
  context: ExtendedContextStore | undefined,
  urlLocation: any,
  removeDialogParam = false
): URL | undefined => {
  if (!context?.site.domain) {
    return undefined;
  }

  const domain = context?.site.domain;
  const search = urlLocation.search || "";
  const path = urlLocation.pathname || "";

  try {
    const referer = new URL(`https://${domain}${path}${search}`);

    if (removeDialogParam && referer?.searchParams.has("pwaDialog")) {
      const searchParams = referer.searchParams;
      searchParams.delete("pwaDialog");
      referer.search = searchParams.toString();
      return referer;
    }

    return referer;
  } catch (e: any) {
    return undefined;
  }
};

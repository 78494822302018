/**
 * Utility function that returns the next friday from a given day (ie, friday of next week)
 * @param: {Date} startDate - Reference date for which we want to find the next friday.
 * @return {Date} - The friday of the week after the week of startDate.
 */
export function getNextFriday(startDate: Date): Date {
  const numberOfDaysUntilFriday = (5 - startDate.getDay()) % 7;
  startDate.setDate(startDate.getDate() + numberOfDaysUntilFriday + 7);

  return startDate;
}

export function convertStringToDate(date: string): Date {
  return new Date(`${date}T00:00:00`);
}

export function getNextDate(date: Date): Date {
  return new Date(date.getDate() + 1, date.getMonth(), date.getFullYear());
}

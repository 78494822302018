const PREFIX = "hw.";

const getFullKey = (key: string) => `${PREFIX}${key}`;

const getTimestamp = () => new Date().getTime();

export interface HotwireStorageContainer<T> {
  d: T;
  _expires: number;
}

/**
 * Gets a value stored in localStorage using the Hotwire ui-core-cache library.
 * If the value is marked as expired, it will not be returned.
 *
 * @param key storage key, without prefix
 */
export function getStorageItem<T>(key: string): T | null {
  let value: T | null = null;

  if (!window) {
    return value;
  }

  const storageKey = getFullKey(key);
  const storedValue = window.localStorage.getItem(storageKey);

  if (storedValue) {
    try {
      const container: HotwireStorageContainer<T> = JSON.parse(storedValue);

      if (getTimestamp() > container._expires) {
        // Remove expired items and don't return their value
        window.localStorage.removeItem(storageKey);
      } else {
        value = container.d;
      }
    } catch (e) {
      // Swallow JSON parsing error - we will return null. Remove the invalid object from storage.0
      window.localStorage.removeItem(storageKey);
    }
  }

  return value;
}

/**
 * Sets a value on localStorage for compatibility with the Hotwire ui-core-cache library.
 *
 * @param key storage key, without prefix
 * @param value object to store (will be stringified)
 * @param maxAge time after which the value will expire (in milliseconds)
 */
export function setStorageItem(key: string, value: any, maxAge: number) {
  if (window) {
    const container: HotwireStorageContainer<any> = {
      d: value,
      _expires: getTimestamp() + maxAge,
    };
    window.localStorage.setItem(getFullKey(key), JSON.stringify(container));
  }
}

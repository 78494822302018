import { ExtendedTemplateComponent, ExtendedTemplateComponentMetadata } from "./typings";
import { FlexComponent } from "src/typings/flexFramework/FlexComponent";
import { DescendantDepth, getNextDepth } from "src/stores/ExperienceTemplateStore";

const getNameFromFlexEntity = (flexEntity: FlexComponent): string => {
  let name = "";

  if (flexEntity.type === "Region") {
    name = "region";
  } else if (flexEntity.type === "Module") {
    name = flexEntity.name;
  } else {
    // probably a TAB
    name = "tab";
  }

  return name;
};

/**
 * Converts a value that should be a boolean, but is maybe a string, to a boolean. If the value is undefined, the function will return undefined
 * This is necessary to deal with complex behavior of compositor and it's inconsistent treatment of some data types
 */
const stringToBoolean = (value?: string | boolean): boolean | undefined => {
  if (value === undefined) {
    return undefined;
  }

  if (typeof value === "boolean") {
    return value;
  }

  if (typeof value === "string") {
    return value.toLowerCase() === "true";
  }

  return !!value;
};

/**
 * creates ExtendedTemplateComponent equivalent of provided  FlexComponent
 * @param {FlexComponent} flexEntity
 * @param {DescendantDepth} descendantDepth specify depth of returned TemplateComponent tree
 * @returns ExtendedTemplateComponent
 */

export const flexToExtendedTemplateComponent = (
  flexEntity: FlexComponent,
  descendantDepth: DescendantDepth
): ExtendedTemplateComponent => {
  const type = getNameFromFlexEntity(flexEntity);

  const view = flexEntity.view !== undefined ? flexEntity.view : flexEntity.model?.view;

  const ajaxOnly =
    flexEntity.ajaxOnly !== undefined
      ? stringToBoolean(flexEntity.ajaxOnly)
      : stringToBoolean(flexEntity.model?.ajaxOnly);

  const clientSideOnly =
    flexEntity.clientSideOnly !== undefined
      ? stringToBoolean(flexEntity.clientSideOnly)
      : stringToBoolean(flexEntity.model?.clientSideOnly);

  const configFromFlexEntity = { ...flexEntity } as any;

  // delete fields from config that are already represented elsewhere in the TemplateComponent
  delete configFromFlexEntity.id;
  delete configFromFlexEntity.fmId;
  delete configFromFlexEntity.name;
  delete configFromFlexEntity.view;
  delete configFromFlexEntity.fmType;
  delete configFromFlexEntity.type;
  delete configFromFlexEntity.model;
  delete configFromFlexEntity.modules;
  delete configFromFlexEntity.children;
  delete configFromFlexEntity.groups;

  // in the composition, the configuration (template attributes) and model (microservice response) are both in the `flexEntity.model` object. This creates a weird state
  // where we cannot have a clean split between the two. When a component is `clientSideOnly` the `flexComponent.model` will only contain configuration, and when it isn't the
  // `flexComponent.model` will contain both configuration and the model. We need to make the configuration available in every case, but there's no easy way to remove the microservice response
  // so it will be duplicated. People shouldn't read model values from the `templateComponent.config` object, but nothing prevents them. Typing needs to be done properly.
  const configFromModel = { ...flexEntity.model } as any;

  // delete fields from config that are already represented elsewhere in the TemplateComponent
  delete configFromModel.id;
  delete configFromModel.name;
  delete configFromModel.view;
  delete configFromModel.fmType;
  delete configFromModel.ajaxOnly;
  delete configFromModel.clientSideOnly;

  const config = {
    ...configFromFlexEntity,
    ...configFromModel,
    ajaxOnly,
    clientSideOnly,
    view,
  } as any;

  const metadata: ExtendedTemplateComponentMetadata = {
    id: `${flexEntity.id}`,
  };

  if (type === "tab" || type === "region") {
    metadata.name = flexEntity.name;
  }

  const templateComponent: ExtendedTemplateComponent = {
    type,
    metadata,
    config,
  };

  if ((flexEntity.modules || flexEntity.groups || flexEntity.children) && descendantDepth !== DescendantDepth.NONE) {
    const childFlexComponents: FlexComponent[] = [
      ...(flexEntity.modules || []),
      ...(flexEntity.groups || []),
      ...(flexEntity.children || []),
    ];

    const childTemplateComponents = childFlexComponents
      .map((child) => flexToExtendedTemplateComponent(child, getNextDepth(descendantDepth)))
      .filter((tc: ExtendedTemplateComponent) => !!tc);

    if (childTemplateComponents.length > 0) {
      templateComponent.children = childTemplateComponents;
    }
  }

  return templateComponent;
};

import { PillId } from "./PackagesEnum";

export interface Pill {
  id: string;
  name: string;
  options: PillOption[];
}

export interface PillOption {
  locKey: string;
  locArg?: number | number[];
  lptParamValue: string;
}

export const tripLengthPillFilter: Pill = {
  id: PillId.TRIP_LENGTH,
  name: "package.pills.tripLength",
  options: [
    { locKey: "datePicker.nightSummary", locArg: 3, lptParamValue: "3" },
    { locKey: "datePicker.nightSummary", locArg: 4, lptParamValue: "4" },
    { locKey: "datePicker.nightSummary", locArg: 5, lptParamValue: "5" },
    { locKey: "datePicker.nightSummary.range", locArg: [6, 7], lptParamValue: "6,7" },
  ],
};

export const starRatingPillFilter = {
  id: PillId.STAR_RATING,
  name: "package.pills.star.rating",
  options: [
    { locKey: "package.pills.5.star", lptParamValue: "5.0" },
    { locKey: "package.pills.4.star", lptParamValue: "4.0" },
    { locKey: "package.pills.3.star", lptParamValue: "3.0" },
    { locKey: "package.pills.2.star", lptParamValue: "2.0" },
  ],
};

export const flightClassPillFilter = {
  id: PillId.FLIGHT_CLASS,
  name: "package.pills.flightClass",
  options: [
    { locKey: "wizard.flight.preferredClass.economy.text", lptParamValue: "Coach" },
    { locKey: "wizard.flight.preferredClass.premiumEconomy.text", lptParamValue: "PremiumEconomy" },
    { locKey: "wizard.flight.preferredClass.business.text", lptParamValue: "BusinessClass" },
    { locKey: "wizard.flight.preferredClass.firstClass.text", lptParamValue: "FirstClass" },
  ],
};

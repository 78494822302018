import { getNameFromFlexComponent } from "src/stores/CompositionStore";
import { Composition } from "src/typings/flexFramework/FlexDefinitions";
import { FlexComponent } from "src/typings/flexFramework/FlexComponent";
import { flexComponentMap } from "src/components/flexFramework/FlexComponents/flexComponentMap";
import { CodeSplit } from "bernie-core";
import {
  ExperienceTemplateRendererComponentLibrary,
  getTemplateManifest,
  TemplateComponent,
  TemplateComponentProps,
} from "experience-template-renderer-react";

// TODO: in the future this typing will be exported by experience-template-renderer-react
type ComponentType = React.ComponentClass<TemplateComponentProps> | React.FC<TemplateComponentProps>;

export interface BlossomCodeSplitterProps {
  composition?: Composition;
  experienceTemplateComponents?: TemplateComponent[];
}

class BlossomCodeSplitter {
  /**
   * experience-template-renderer-react compatible component library based on flexComponentMap.ts (aliases have been expanded into the library)
   */
  public componentLibrary: ExperienceTemplateRendererComponentLibrary = new Map();
  private webpackChunkNameMap = new Map<string, string>();

  public constructor() {
    flexComponentMap.forEach((mapping, key) => {
      this.componentLibrary.set(key, (mapping.component as unknown) as ComponentType);
      this.webpackChunkNameMap.set(key, `blossom-${key}`);

      if (mapping.aliases) {
        mapping.aliases.forEach((alias) => {
          this.componentLibrary.set(alias, (mapping.component as unknown) as ComponentType);
          this.webpackChunkNameMap.set(alias, `blossom-${key}`);
        });
      }
    });
  }

  /**
   * Return the component from componentLibrary based on a componentType and optional view parameter. Uses libraryKeyResolver() to find the most precise match.
   * This is a duplicate of experience-template-renderer-react functionality. This method can be deleted once Blossom has fully adopted experience-template-renderer-react.
   * @param componentType string
   * @param view string |undefined
   * @returns CodeSplit | undefined
   */
  public getComponentFromLibrary = (componentType: string, view?: string) => {
    const templateComponent: TemplateComponent = {
      type: componentType,
      config: {
        view: view,
      },
    };

    const resolvedLibraryKey = this.libraryKeyResolver(templateComponent, [...this.componentLibrary.keys()]);

    if (resolvedLibraryKey) {
      return this.componentLibrary.get(resolvedLibraryKey);
    }

    return;
  };

  /**
   * experience-template-renderer-react compatible libraryKeyResolver.
   * Given a component's type and it's optional view parameter, resolve the key that will return the most appropriate component from componentLibrary. It is not guaranteed the returned key will always have an entry in componentLibrary, only that the key is as precise as possible.
   * For more info - https://pages.github.expedia.biz/Expedia-UI/experience-template-renderer-react/components/experience-template-renderer-context-provider#librarykeyresolver
   * @param templateComponent TemplateComponent
   * @param libraryKeys string[]
   * @returns string
   */
  public libraryKeyResolver = (templateComponent: TemplateComponent, libraryKeys: string[]): string | null => {
    if (!templateComponent || !templateComponent.type) {
      return null;
    }

    const view = templateComponent.config?.["view" as keyof object];
    const viewSpecificKey = view ? `${templateComponent.type}_${view}` : undefined;

    if (viewSpecificKey && libraryKeys.includes(viewSpecificKey)) {
      return viewSpecificKey;
    }

    return templateComponent.type;
  };

  /**
   * experience-template-renderer-react compatible libraryKeyResolver.
   * Given a component's type and it's optional view parameter, resolve the key that will return the most appropriate component from componentLibrary. It is not guaranteed the returned key will always have an entry in componentLibrary.
   * For more info - https://pages.github.expedia.biz/Expedia-UI/experience-template-renderer-react/components/experience-template-renderer-context-provider#librarykeyresolver
   * @param templateComponent TemplateComponent
   * @param libraryKeys string[]
   * @returns string
   */
  public experienceTemplateLibraryKeyResolver = (
    templateComponent: TemplateComponent,
    libraryKeys: string[]
  ): string | null => {
    const key = this.libraryKeyResolver(templateComponent, libraryKeys);

    if (!key) {
      return null;
    }

    return key;
  };

  /**
   * Returns a de-duped array of keys for componentLibrary that correspond to the provided Flex Template or Composition. If no template or composition is provided, all keys from componentLibrary will be returned.
   * @param input BlossomCodeSplitterProps
   * @returns string[]
   */
  private getRequiredComponentLibraryKeys = (input: BlossomCodeSplitterProps): string[] => {
    const { composition, experienceTemplateComponents } = input;
    const requiredComponentLibraryKeys = new Set<string>();

    const collectChildren = (node: FlexComponent) => [
      ...(node.modules || []),
      ...(node.groups || []),
      ...(node.children || []),
    ];

    if (composition) {
      const reducer = (acc: Set<string>, flexComponent: FlexComponent) => {
        const libraryKey = this.libraryKeyResolver(
          {
            type: getNameFromFlexComponent(flexComponent),
            config: {
              view: flexComponent.model?.view,
            },
          },
          [...this.componentLibrary.keys()]
        );

        if (libraryKey) {
          if (!this.componentLibrary.has(libraryKey)) {
            return acc;
          }

          acc.add(libraryKey);
        }

        collectChildren(flexComponent).forEach((child) => {
          reducer(acc, child as FlexComponent);
        });

        return acc;
      };

      return [...composition.page.regionList.reduce(reducer, requiredComponentLibraryKeys)];
    }

    if (experienceTemplateComponents) {
      const { supported } = getTemplateManifest(
        { components: experienceTemplateComponents },
        this.componentLibrary,
        this.experienceTemplateLibraryKeyResolver
      );

      return supported;
    }

    // no template or composition provided, return all keys
    return [...this.componentLibrary.keys()];
  };

  /**
   * Returns de-duped array of CodeSplit objects from componentLibrary that correspond to the provided Flex Template or Composition. If no template or composition is provided, all entries will be returned.
   * @param input BlossomCodeSplitterProps
   * @returns CodeSplit[]
   */
  public getRequiredCodeSplitBundles = (input: BlossomCodeSplitterProps) => {
    const requiredLibraryItems: CodeSplit[] = [];

    const requiredLibraryKeys = this.getRequiredComponentLibraryKeys(input);

    requiredLibraryKeys.forEach((libraryKey) => {
      const libraryItem = this.componentLibrary.get(libraryKey);
      if (libraryItem) {
        requiredLibraryItems.push((libraryItem as unknown) as CodeSplit);
      }
    });

    return requiredLibraryItems;
  };

  /**
   * returns a deduped array of webpackChunkNames corresponding to items in componentLibrary required by the provided Flex Template or Composition. If no template or composition is provided, webpackChunkNames associated with all items in componentLibrary will be returned
   * @param input
   * @returns string[]
   */
  public getRequiredWebpackChunkNames = (input: BlossomCodeSplitterProps) => {
    const requiredWebpackChunkNames = new Set<string>();
    const requiredLibraryKeys = this.getRequiredComponentLibraryKeys(input);

    requiredLibraryKeys.forEach((key) => {
      const chunkName = this.webpackChunkNameMap.get(key);
      if (chunkName) {
        requiredWebpackChunkNames.add(chunkName);
      }
    });

    return [...requiredWebpackChunkNames];
  };

  /**
   * loads webpack code split chunks required to render the provided Flex Template or Composition. If no template or composition is provided, all webpack code split chunks will be loaded.
   * @param input
   * @returns Promise
   */
  public loadRequiredCodeSplitBundles = (input: BlossomCodeSplitterProps) => {
    const requiredCodeSplitBundles = this.getRequiredCodeSplitBundles(input)
      .filter((bundle) => bundle && typeof (bundle as any).loadRoute === "function")
      .map((bundle) => bundle && (bundle as any).loadRoute());

    return Promise.all(requiredCodeSplitBundles);
  };
}

export const blossomCodeSplitter = new BlossomCodeSplitter();

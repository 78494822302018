import * as React from "react";
import { PropertyFiltersComponentProps } from "../typings";
import { UitkRadioButton } from "uitk-react-radio-button";
import { withStores } from "src/stores";
import { observer } from "mobx-react";
import { UitkSpacing } from "uitk-react-spacing";

export const Radio = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentProps) => {
    const { filterName, option, propertyFilters } = props;
    const { optionValue, description, label } = option;

    const isSelected = propertyFilters.isSelected(filterName, optionValue ?? "");

    return (
      <UitkSpacing margin="half">
        <UitkRadioButton
          id={`${filterName}-${optionValue}`}
          value={optionValue ?? ""}
          checked={isSelected}
          description={description ?? ""}
        >
          {label}
        </UitkRadioButton>
      </UitkSpacing>
    );
  })
);

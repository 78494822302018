type SetState = (value: boolean) => void;

export class PropertyFilterPublisher {
  private filters: Map<string, SetState[]>;

  constructor() {
    this.filters = new Map<string, SetState[]>();
  }

  public subscribe(key: string, callback: SetState) {
    const prev = this.filters.get(key) || [];
    this.filters.set(key, [...prev, callback]);
  }

  public next(key: string, value: boolean) {
    this.filters.get(key)?.map((setState: SetState) => {
      setState(value);
    });
  }
}

export default PropertyFilterPublisher;

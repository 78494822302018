import * as fetch from "isomorphic-fetch";
import * as md5 from "md5";

const getTimestamp = () => Math.round(new Date().getTime() / 1000);

export interface HotwireApiConfig {
  url: string;
  key: string;
  secret: string;
}

export interface HotwireFetchQueryParams {
  [key: string]: string;
}

export enum HotwireFetchMethod {
  GET = "GET",
  POST = "POST",
}

export interface HotwireFetchOptions {
  method: HotwireFetchMethod;
  query?: HotwireFetchQueryParams;
  body?: any;
  pathParams?: string;
}

const getUrl = (config: HotwireApiConfig, query?: HotwireFetchQueryParams, pathParams?: string): string => {
  const queryParams = {
    apikey: config.key,
    sig: getSignature(config),
    ...query,
  };
  const urlSearchParams = new URLSearchParams(queryParams);

  return `${config.url}${pathParams ? `/${pathParams}` : ""}?${urlSearchParams.toString()}`;
};

const getSignature = (config: HotwireApiConfig): string => md5(config.key + config.secret + getTimestamp());

/**
 * Provides abstraction over an Hotwire client-side API call which requires specific Mashery headers.
 *
 * @param config API config containing base url, key, secret
 * @param options Options to override the default fetch behavior
 */
export const fetchHotwireApi = (config: HotwireApiConfig, options: HotwireFetchOptions): Promise<Response> => {
  const { method, pathParams, query, body } = options;

  const fetchOptions: RequestInit = {
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "x-channel-id": "blossom-flex-ui",
    },
    method,
  };

  if (method === "POST" && body) {
    fetchOptions.body = JSON.stringify(body);
  }

  return fetch(getUrl(config, query, pathParams), fetchOptions);
};

import { Store, AnalyticsStore } from "bernie-plugin-mobx";
import { ChatbotStore } from "./ChatbotStore";
import { SerializedData } from "bernie-core";
import { Logger, NOOP_LOGGER } from "bernie-logger";
import { FAILED_CHATBOT_CONVERSATION_CONTEXT, FAILED_CHATBOT_OPEN_WIDGET } from "config/systemEvents";

interface VacEvent {
  type: string;
}

export interface WebChatObject {
  openChatWidget: () => void;
  setConversationContext: (arg0: object) => void;
}

export interface VirtualAgentControlAdapter {
  getInstance: () => Promise<WebChatObject>;
}

export interface LegacyChatClient extends WebChatObject {
  isInitialized: Promise<any>;
}

export interface VirtualAgentControlClass {
  initialized: Promise<any>;
}

//currently a subset of: https://github.expedia.biz/ECP/gco-c3-web-messenger/blob/aac116d5960fc34ef365fa14ca908838860972ba/src/c3-web-chat.js
export interface WindowWithChatbot extends Window {
  virtualAgentControl?: LegacyChatClient;
  VirtualAgentControl?: VirtualAgentControlClass;
  VacClientAdapter?: VirtualAgentControlAdapter;
}

export interface ConversationProperties {
  launchPoint: string;
  pageName: string;
}

export interface ConversationIntent {
  intent: string;
}

export enum IntentTypes {
  VIEW_BOOKING = "CHANGE",
  REFUND_STATUS = "REFUND_STATUS",
}

export enum VacEventTypes {
  VAC_WIDGET_OPENED_EVENT = "VAC_WidgetOpened",
  VAC_WIDGET_CLOSED_EVENT = "VAC_WidgetClosed",
  VAC_WIDGET_READY_EVENT = "VAC_Ready",
  VAC_WIDGET_OPENED_RFRR_ID = "VA.FAB.Open",
  VAC_WIDGET_CLOSED_RFRR_ID = "VA.FAB.Closed",
  VAC_WIDGET_READY_RFRR_ID = "VA.FAB.Ready",
  VAC_WIDGET_OPENED_DESCRIPTION = "VAC Widget Opened",
  VAC_WIDGET_CLOSED_DESCRIPTION = "VAC Widget Closed",
  VAC_WIDGET_READY_DESCRIPTION = "VAC Widget Ready",
}

export enum VacLoggingTypes {
  VAC_BAD_RESPONSE_ERROR = "CHATBOT_INIT_ERROR_VAC_BAD_RESPONSE",
  VAC_INIT_ERROR = "CHATBOT_INIT_ERROR_CALLER_INCORRECT_CALL",
  VAC_SUCCESS = "CHATBOT_INITIALIZED",
}

export class ClientChatbotStore extends Store implements ChatbotStore {
  public locale?: string;

  public chatbotUseVacPath?: boolean;

  public subscribedEvents = [
    VacEventTypes.VAC_WIDGET_OPENED_EVENT,
    VacEventTypes.VAC_WIDGET_CLOSED_EVENT,
    VacEventTypes.VAC_WIDGET_READY_EVENT,
  ];

  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
  }

  public hydrate(data: SerializedData): void {
    this.locale = data.locale;
    this.chatbotUseVacPath = data.chatbotUseVacPath;
  }

  // deprecated
  public injectChatbotScriptAndInitializeOnLoad(document: Document, analytics: AnalyticsStore, initLogger: Logger) {
    return true;
  }

  public async isVirtualAgentInitialized(window: WindowWithChatbot): Promise<boolean> {
    return (await this.getInitedWebChatObject(window)) !== undefined;
  }

  public eventHandler(analyticsStore: AnalyticsStore, event: VacEvent) {
    switch (event.type) {
      case VacEventTypes.VAC_WIDGET_OPENED_EVENT:
        analyticsStore.trackEvent(
          VacEventTypes.VAC_WIDGET_OPENED_RFRR_ID,
          VacEventTypes.VAC_WIDGET_OPENED_DESCRIPTION,
          analyticsStore.omnitureData
        );
        break;
      case VacEventTypes.VAC_WIDGET_CLOSED_EVENT:
        analyticsStore.trackEvent(
          VacEventTypes.VAC_WIDGET_CLOSED_RFRR_ID,
          VacEventTypes.VAC_WIDGET_CLOSED_DESCRIPTION,
          analyticsStore.omnitureData
        );
        break;
      case VacEventTypes.VAC_WIDGET_READY_EVENT:
        analyticsStore.trackEvent(
          VacEventTypes.VAC_WIDGET_READY_RFRR_ID,
          VacEventTypes.VAC_WIDGET_READY_DESCRIPTION,
          analyticsStore.omnitureData
        );
        break;
      default:
        break;
    }
  }

  public async startConversation(
    conversationProperties: ConversationProperties,
    conversationIntent: ConversationIntent
  ) {
    try {
      const initedChatClient = await this.getInitedWebChatObject(window as WindowWithChatbot);
      if (initedChatClient) {
        const conversationContext = {
          conversationProperties,
          intentMessage: conversationIntent,
        };
        initedChatClient?.setConversationContext(conversationContext);
      }
    } catch (e) {
      this.logger.logEvent(
        FAILED_CHATBOT_CONVERSATION_CONTEXT,
        "Unexpected error occurred while setting conversation context to the VAC",
        e
      );

      return false;
    }

    return true;
  }

  public async openChatWidget() {
    try {
      const initedChatClient = await this.getInitedWebChatObject(window as WindowWithChatbot);
      if (initedChatClient) {
        initedChatClient?.openChatWidget();
      }
    } catch (e) {
      this.logger.logEvent(FAILED_CHATBOT_OPEN_WIDGET, "Unexpected error occurred while opening the VAC window", e);

      return false;
    }

    return true;
  }

  private async getInitedWebChatObject(window: WindowWithChatbot) {
    if (window.virtualAgentControl && (await window.virtualAgentControl.isInitialized)) {
      return window.virtualAgentControl;
    }
    if (window.VirtualAgentControl && window.VacClientAdapter && (await window.VirtualAgentControl.initialized)) {
      return window.VacClientAdapter.getInstance();
    }

    return undefined;
  }
}

// Define actions, computed values and all necesary to manage the state client side
import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { Logger, NOOP_LOGGER } from "bernie-logger";
import { observable, computed, action, makeObservable } from "mobx";
import {
  PackageWizardState,
  GlobalWizardState,
  LxWizardState,
  VrWizardState,
  CarWizardState,
  HotelWizardState,
  ThirdPartyPackageWizardState,
  CruiseWizardState,
  FlightWizardState,
  ExternalLinkTabWizardState,
  LOBState,
} from "stores/wizard/state";
import { MultiLOBWizardConfig } from "./config";
import { WizardStore } from "./WizardStore";
import { LOB_KEYS } from "components/shared/StorefrontWizard/constants";

export class ClientWizardStateStore extends Store implements WizardStore {
  public multiLOBWizardConfig: MultiLOBWizardConfig;
  public lobStateMap: any;
  public canTrack: boolean;

  public get defaultLob() {
    return this.globalWizardState?.defaultLob;
  }

  public get useDefaultLOB() {
    return this.globalWizardState.useDefaultLOB;
  }

  public setUseDefaultLOB(useDefaultLOB: boolean) {
    this.globalWizardState.useDefaultLOB = useDefaultLOB;
  }

  public get activeLob() {
    return this.globalWizardState?.activeLob;
  }

  public setActiveLob(activeLob: string) {
    this.globalWizardState.setActiveLob(activeLob);
    if (this.lobStateMap[activeLob]) {
      (this.lobStateMap[activeLob] as LOBState).resetValidations();
    }
    if (activeLob === LOB_KEYS.GT) {
      this.lobStateMap[LOB_KEYS.CAR].groundTransportationLocationState.resetValidations();
    }
  }

  public globalWizardState: GlobalWizardState;
  public packageWizardState: PackageWizardState;
  public lxWizardState: LxWizardState;
  public externalLinkTabWizardState: ExternalLinkTabWizardState;
  public vrWizardState: VrWizardState;
  public carWizardState: CarWizardState;
  public hotelWizardState: HotelWizardState;
  public flightWizardState: FlightWizardState;
  public thirdPartyPackageWizardState: ThirdPartyPackageWizardState;
  public cruiseWizardState: CruiseWizardState;

  constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
    makeObservable(this, {
      multiLOBWizardConfig: observable,
      lobStateMap: observable,
      canTrack: observable,
      globalWizardState: observable,
      packageWizardState: observable,
      lxWizardState: observable,
      externalLinkTabWizardState: observable,
      vrWizardState: observable,
      carWizardState: observable,
      hotelWizardState: observable,
      flightWizardState: observable,
      thirdPartyPackageWizardState: observable,
      cruiseWizardState: observable,
      setActiveLob: action,
      defaultLob: computed,
      useDefaultLOB: computed,
      activeLob: computed,
    });
  }

  public hydrate(data: SerializedData): void {
    if (!data.multiLOBWizardConfig) {
      return;
    }

    this.multiLOBWizardConfig = data.multiLOBWizardConfig as MultiLOBWizardConfig;
    this.canTrack = data.canTrack as boolean;

    this.globalWizardState = new GlobalWizardState(this.multiLOBWizardConfig.global, this.canTrack);

    this.packageWizardState = new PackageWizardState(this.multiLOBWizardConfig.package, this.globalWizardState);

    this.lxWizardState = new LxWizardState(this.multiLOBWizardConfig.lx, this.globalWizardState);

    this.vrWizardState = new VrWizardState(this.multiLOBWizardConfig.vr, this.globalWizardState);

    this.carWizardState = new CarWizardState(this.multiLOBWizardConfig.car, this.globalWizardState);

    this.hotelWizardState = new HotelWizardState(this.multiLOBWizardConfig.hotel, this.globalWizardState, this.logger);

    this.flightWizardState = new FlightWizardState(this.multiLOBWizardConfig.flight, this.globalWizardState);

    this.thirdPartyPackageWizardState = new ThirdPartyPackageWizardState(
      this.multiLOBWizardConfig.thirdPartyPackage,
      this.globalWizardState
    );

    this.cruiseWizardState = new CruiseWizardState(this.multiLOBWizardConfig.cruise, this.globalWizardState);

    this.externalLinkTabWizardState = new ExternalLinkTabWizardState(this.multiLOBWizardConfig.externalLinkTab);

    this.lobStateMap = {
      "wizard-flight-pwa": this.flightWizardState,
      "wizard-car-pwa": this.carWizardState,
      "wizard-vr-pwa": this.vrWizardState,
      "wizard-package-pwa": this.packageWizardState,
      "wizard-lx-pwa": this.lxWizardState,
      "wizard-thirdPartyPackage-pwa": this.thirdPartyPackageWizardState,
      "wizard-cruise-pwa": this.cruiseWizardState,
      "wizard-hotel-pwa": this.hotelWizardState,
      "wizard-hotel-pwa-v2": this.hotelWizardState,
      "wizard-externalLinkTab-pwa": this.externalLinkTabWizardState,
    };
  }
}

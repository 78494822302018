/* istanbul ignore file */
import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { Logger } from "bernie-logger";
import { MarkdownStore } from "./typings";

export class ClientMarkdownStore extends Store implements MarkdownStore {
  private compiledMarkdown: { [key: string]: string } = {};

  public constructor(state: SerializedData, logger: Logger) {
    super(state, logger);
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  public compileMarkdown = (key: string): string => {
    return this.compiledMarkdown[key];
  };
}

const { typePolicies } = require("@shared-ui/apollo-type-policies");

module.exports = {
  ...typePolicies,
  PropertyInfo: {
    merge: true,
    fields: {
      offers: {
        merge: true,
      },
      reviewInfo: {
        merge: true,
      },
      similarProperties: {
        merge: true,
      },
    },
  },
  Property: {
    fields: {
      price: {
        merge: true,
      },
    },
  },
};

import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { observable, action, makeObservable } from "mobx";
import { Logger, NOOP_LOGGER } from "bernie-logger";

export class DestinationSuggestionsStore extends Store {
  public destinationSuggestionsOrigin?: string;

  /* istanbul ignore next */
  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
    makeObservable(this, {
      destinationSuggestionsOrigin: observable,
      setupDestinationSuggestions: action,
      setOriginForDestinationSuggestions: action,
    });
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
    this.setupDestinationSuggestions();
  }

  public setupDestinationSuggestions() {
    this.destinationSuggestionsOrigin = "";
  }

  public setOriginForDestinationSuggestions(destinationSuggestionsOrigin?: string) {
    this.destinationSuggestionsOrigin = destinationSuggestionsOrigin;
  }
}

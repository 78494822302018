import * as React from "react";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { PropertyFiltersComponentProps } from "../typings";
import { UitkCheckbox } from "uitk-react-checkbox";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { UitkSpacing } from "uitk-react-spacing";

export enum CheckboxModuleNameTypes {
  PROPERTY_FILTERS = "PropertyFilters",
  WIZARD_HOTEL_V2_ROW = "WizardHotelPWAv2ROW",
}

export const Checkbox = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentProps) => {
    const track = useClickTracker();
    const { filterName, option, propertyFilters, moduleName, onChange: handleChange } = props;

    const onChange = () => {
      propertyFilters.toggleSelection(filterName, option.optionValue!);

      const rfrr = `${moduleName}.${filterName}.${option.optionValue}.${!isSelected}.click`;
      const linkName = "RFRR Action Link";
      track(rfrr, linkName);

      handleChange?.(filterName, option);
    };

    const isSelected = propertyFilters.isSelected(filterName, option.optionValue!);

    const categoryName = option.filterCategory ?? filterName;

    return (
      <UitkSpacing margin="half">
        <div>
          <UitkCheckbox
            id={`${moduleName}-${filterName}-${option.optionValue}`}
            name={categoryName}
            value={option.optionValue ?? ""}
            checked={isSelected}
            onChange={onChange}
            description={option.description ?? ""}
            disabled={option.disabled}
          >
            {option.label}
          </UitkCheckbox>
        </div>
      </UitkSpacing>
    );
  })
);

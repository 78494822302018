import { ContextStore, PageStore, ExperimentStore } from "bernie-plugin-mobx";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

/**
 * Return bucket number of experiment.
 * @param {ExtendedContextStore} context - Store where the experiments array live
 * @param {string} experimentName - Experiment name used (check out link)
 * @returns {number} - Experiment Bucket
 */

export const getExperimentBucket = (context: ExtendedContextStore | undefined, experimentName: string): number =>
  context?.experimentContext?.experiments?.[experimentName] || 0;

/**
 * Checks if an experiment is in a given variant.
 * {@link https://tnl.prod.expedia.com}
 * @param {ExtendedContextStore} context - Store where the experiments array live
 * @param {string} experimentName - Experiment name used (check out link)
 * @param {number} value - Defaults to 1. Value that we are checking the experiment against
 * @returns {boolean} - True when the context contains an experiment string that matches a given numeric value
 */
export const isVariantEnabled = (
  context: ExtendedContextStore | undefined,
  experimentName: string,
  value = 1
): boolean => getExperimentBucket(context, experimentName) === value;

/**
 * isVariantEnabledAndLog
 *
 * Checks whether an experiment is in a given bucket and logs the experiment.
 *
 * @param {string} experimentName Experiment name (programmatic, not friendly name)
 * @param {ContextStore} context ContextStore (Bernie), or ExtendedContextStore (Flex).
 * @param {ExperimentStore} experiment ExperimentStore (Bernie)
 * @param {PageStore} page PageStore (Bernie)
 * @param {number} bucket Value that we are checking the experiment against. Defaults to 1.
 *
 * @returns {boolean}
 */
export const isVariantEnabledAndLog = (
  experimentName: string,
  context: ContextStore,
  experiment: ExperimentStore,
  page: PageStore,
  bucket = 1
): boolean => {
  const exposure = experiment.exposures?.[experimentName];

  if (!exposure) {
    return false;
  }

  experiment.logExperiment(context, page, exposure);

  return exposure.bucket === bucket;
};

import { SystemEvent, SystemEventLevel } from "bernie-logger";

/**
 * ERROR-level SystemEvents
 * @type {SystemEvent}
 */
export const MISSING_CONTEXT_AND_FLEX_VIEWMODEL = new SystemEvent(
  SystemEventLevel.ERROR,
  "MISSING_CONTEXT_AND_FLEX_VIEWMODEL"
);
export const FAILED_MODEL_RENDER = new SystemEvent(SystemEventLevel.ERROR, "FAILED_MODEL_RENDER");
export const FAILED_ANALYTICS = new SystemEvent(SystemEventLevel.ERROR, "FAILED_ANALYTICS");
export const FAILED_MODULE_COMPOSITION_FETCH = new SystemEvent(
  SystemEventLevel.ERROR,
  "FAILED_MODULE_COMPOSITION_FETCH"
);

export const FAILED_REACTIVE_MODULES_COMPOSITION_FETCH = new SystemEvent(
  SystemEventLevel.ERROR,
  "FAILED_REACTIVE_MODULES_COMPOSITION_FETCH"
);

export const FAILED_REGION_RENDER = new SystemEvent(SystemEventLevel.ERROR, "FAILED_REGION_RENDER");
export const FAILED_CHATBOT_CONVERSATION_CONTEXT = new SystemEvent(
  SystemEventLevel.ERROR,
  "FAILED_CHATBOT_CONVERSATION_CONTEXT"
);
export const FAILED_CHATBOT_OPEN_WIDGET = new SystemEvent(SystemEventLevel.ERROR, "FAILED_CHATBOT_OPEN_WIDGET");
export const FAILED_CAMPAIGN_FETCH = new SystemEvent(SystemEventLevel.ERROR, "FAILED_CAMPAIGN_FETCH");
export const FAILED_HOTEL_FETCH = new SystemEvent(SystemEventLevel.ERROR, "FAILED_HOTEL_FETCH");
export const FAILED_DESTINATION_PRICING = new SystemEvent(SystemEventLevel.ERROR, "FAILED_DESTINATION_PRICING");
export const FAILED_COUPON_FETCH = new SystemEvent(SystemEventLevel.ERROR, "FAILED_COUPON_FETCH");
export const BEX_API_FETCH_ERROR = new SystemEvent(SystemEventLevel.ERROR, "BEX_API_FETCH_ERROR");
export const FAILED_HOTW_DISCOUNT_CODE_FETCH = new SystemEvent(
  SystemEventLevel.ERROR,
  "FAILED_HOTW_DISCOUNT_CODE_FETCH"
);
export const FAILED_TRAVEL_AGENT_SEARCH_WIZARD_CONFIG_FETCH = new SystemEvent(
  SystemEventLevel.ERROR,
  "FAILED_TRAVEL_AGENT_SEARCH_WIZARD_CONFIG_FETCH"
);

/**
 * WARN-level SystemEvents
 * @type {SystemEvent}
 */
export const UNSUPPORTED_MODULE = new SystemEvent(SystemEventLevel.WARN, "UNSUPPORTED_MODULE");
export const NO_PROPERTIES_FOUND = new SystemEvent(SystemEventLevel.WARN, "NO_PROPERTIES_FOUND");
export const POI_FETCH_ERROR = new SystemEvent(SystemEventLevel.WARN, "POI_FETCH_ERROR");
export const MESO_AD_LOAD_FAIL = new SystemEvent(SystemEventLevel.WARN, "MESO_AD_LOAD_FAIL");
export const MESO_AD_COLLAPSE = new SystemEvent(SystemEventLevel.WARN, "MESO_AD_COLLAPSE");
export const MESO_SPONSORED_CONTENT_ERROR = new SystemEvent(SystemEventLevel.WARN, "MESO_SPONSORED_CONTENT_ERROR");
export const INTENT_MEDIA_ERROR = new SystemEvent(SystemEventLevel.WARN, "INTENT_MEDIA_ERROR");
export const FAILED_HOTW_CUSTOMER_STATS = new SystemEvent(SystemEventLevel.WARN, "FAILED_HOTW_CUSTOMER_STATS");
export const FAILED_HOTW_CID_IDENTIFY = new SystemEvent(SystemEventLevel.WARN, "FAILED_HOTW_CID_IDENTIFY");
export const FAILED_HOTW_ADOBE_MID = new SystemEvent(SystemEventLevel.WARN, "FAILED_HOTW_ADOBE_MID");

/**
 * INFO-level SystemEvents
 * @type {SystemEvent}
 */
export const TRACK_EVENT = new SystemEvent(SystemEventLevel.INFO, "TRACK_EVENT");

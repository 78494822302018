import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { Logger } from "bernie-logger";
import { observable, action, makeObservable } from "mobx";

import { JumplinkItem } from "components/flexComponents/Jumplinks/typings";
import { DestinationWhereToStayCategories } from "typings/microserviceModels/destination-where-to-stay-flex-module";
import { FlexComponent } from "src/typings/flexFramework/FlexComponent";
import { FlexViewModelResponse } from "src/typings/flexFramework/FlexDefinitions";

enum DxRegionType {
  DX_MAIN_CONTENT_REGION = "dx-main-content",
  DX_PAGE_CONTENT_REGION = "dx-page-content",
}

enum DxModuleType {
  DX_USER_REVIEWS = "destination-user-reviews",
  DX_WHERE_TO_STAY = "destination-where-to-stay",
}

export enum JumplinkablePageType {
  DX_DEFAULT = "default",
  DX_WHERE_TO_STAY = "whereToStay",
}

export class DXStore extends Store {
  public jumplinksArray: JumplinkItem[] = [];

  public constructor(state = {}, logger: Logger) {
    super(state, logger);

    makeObservable(this, {
      jumplinksArray: observable,
      setJumplinksArray: action,
    });
  }

  public setJumplinksArray = (jumplinks: JumplinkItem[]) => {
    this.jumplinksArray = jumplinks;
  };

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  public setupInitialJumplinks = (viewModelResponse?: FlexViewModelResponse): void => {
    if (!viewModelResponse?.composition) {
      return;
    }

    const dxMainContentRegion = viewModelResponse.composition.page.regionList.find(
      (region) => region.name === DxRegionType.DX_MAIN_CONTENT_REGION
    );

    if (!dxMainContentRegion) {
      return;
    }

    const dxPageContentRegion = dxMainContentRegion.modules.find(
      (region) => region.name === DxRegionType.DX_PAGE_CONTENT_REGION
    );

    if (!dxPageContentRegion?.model?.jumpLinkable) {
      return;
    }

    this.setJumplinksArray(this.buildJumplinks(dxPageContentRegion));
  };

  private buildJumplinks = (dxPageContentRegion: FlexComponent): JumplinkItem[] => {
    const { jumpLinkablePageType } = dxPageContentRegion.model;

    if (jumpLinkablePageType === JumplinkablePageType.DX_WHERE_TO_STAY) {
      return this.buildWhereToStayJumplinks(jumpLinkablePageType, dxPageContentRegion);
    }

    return dxPageContentRegion.modules.reduce((arr: JumplinkItem[], module: FlexComponent) => {
      if (!this.isValidJumplinkableModule(module)) {
        return arr;
      }

      const jumplink = {
        id: module.id,
        moduleName: module.name,
        pathName: module.model.jumplinkPathName,
        pageType: jumpLinkablePageType,
        label: module.model.jumplinkLabel,
      };

      arr.push(jumplink);

      return arr;
    }, []);
  };

  private buildWhereToStayJumplinks = (pageType: string, region: FlexComponent): JumplinkItem[] => {
    const dxWhereToStayModule = region.modules.find(
      (module: FlexComponent) => module.name === DxModuleType.DX_WHERE_TO_STAY
    );

    if (!dxWhereToStayModule) {
      return [];
    }

    const { categories, source } = dxWhereToStayModule.model;

    if (!(categories && source)) {
      return [];
    }

    const isCgsContent = source.toLowerCase() === "cgs";

    return categories.map((category: DestinationWhereToStayCategories) => {
      const { title } = category;
      const categoryTitle = isCgsContent ? title.replace("<h2>", "").replace("</h2>", "") : title;

      return {
        id: categoryTitle,
        moduleName: categoryTitle,
        pathName: categoryTitle.replace(/ /g, "-").toLowerCase(),
        pageType,
      };
    });
  };

  private isValidJumplinkableModule = (module: FlexComponent): boolean => {
    const { model, name: moduleName } = module;

    if (!model || !model.jumpLinkable || model.empty) {
      return false;
    }

    if (moduleName !== DxModuleType.DX_USER_REVIEWS) {
      return true;
    }

    const { reviews, aggregateReview } = model;
    const { totalReviews, totalRatings } = aggregateReview;

    return Boolean(reviews.length && totalReviews && totalRatings);
  };
}

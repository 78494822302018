import { ButtonToggleGroup } from "../Groupings/ButtonToggleGroup";
import { RadioButtonGroup } from "../Groupings/RadioButtonGroup";
import { CheckboxGroup } from "../Groupings/CheckboxGroup";
import { SelectGroup } from "../Groupings/SelectGroup";
import { PriceFilterComponentMap } from "./PriceFilterComponentMap";

/**
 * Represents a map that provides a group component for its corresponding filter option component.
 *
 * E.g. Property Class (star) needs to be wrapped with UitkButtonToggleGroup in order to be rendered in a "row" direction.
 */
export const PROPERTY_FILTERS_COMPONENT_GROUP_MAP: any = {
  deals: RadioButtonGroup,
  star: ButtonToggleGroup,
  price: PriceFilterComponentMap,
  lodging: CheckboxGroup,
  amenities: CheckboxGroup,
  regionId: RadioButtonGroup,
  travelerType: CheckboxGroup,
  poi: RadioButtonGroup,
  popularFilter: CheckboxGroup,
  guestRating: RadioButtonGroup,
  accessibility: CheckboxGroup,
  paymentType: CheckboxGroup,
  bedroomFilter: RadioButtonGroup,
  propertyStyle: CheckboxGroup,
  commissionTier: RadioButtonGroup,
  mealPlan: CheckboxGroup,
  cleaningAndSafetyPractices: CheckboxGroup,
  sort: SelectGroup,
};

export const locKeys = {
  flightPreferredClassEconomy: "wizard.flight.preferredClass.economy.text",
  flightPreferredClassBusiness: "wizard.flight.preferredClass.businessClass.text",
  flightPreferredClassFirstClass: "wizard.flight.preferredClass.firstClass.text",
  flightPreferredClassPremiumEconomy: "wizard.flight.preferredClass.premiumEconomy.text",
  flightPreferredClassLabel: "wizard.package.preferredFlightClass.label",
  dialogTitleToken: "wizard.package.preferredFlightClass.label",
  dialogCancelLabelToken: "wizard.flight.preferredClass.dialog.cancelButton",
  dialogDoneButton: "wizard.roomPicker.doneButton.label",
};

import { codeSplit, Controller, FetchOptions, FetchPageOptions } from "bernie-core";
import { PageData } from "bernie-http";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { CompositionStore } from "src/stores/CompositionStore";
import { blossomCodeSplitter } from "src/components/flexFramework/CodeSplitter/BlossomCodeSplitter";

interface WindowWithState extends Window {
  __PLUGIN_STATE__: {
    controllers: {
      stores: {
        compositionStore: CompositionStore;
      };
    };
  };
}

declare let window: WindowWithState;

interface FlexFetchPageOptions extends FetchPageOptions {
  context: ExtendedContextStore;
}

export class FlexDashboardController implements Controller {
  public pageId = "Dashboard";

  public path = "/dashboard";

  public routeName = "dashboard";

  public bundles = ["dashboard"];

  public exact = false;

  public component = codeSplit(async () => {
    const routeComponent = import(/* webpackChunkName: "blossom-FlexDashboard" */ "views/FlexDashboard") as any;
    let composition;

    // Client-side only
    if (typeof window !== "undefined") {
      const serializedStores = window.__PLUGIN_STATE__.controllers.stores;
      composition = serializedStores.compositionStore?.composition;
    }

    await blossomCodeSplitter.loadRequiredCodeSplitBundles({ composition });

    return routeComponent;
  });

  public fetch(options: FetchOptions): Promise<any> {
    return Promise.resolve({});
  }

  public fetchPageData(options: FlexFetchPageOptions): Promise<PageData> {
    return Promise.resolve({ title: "Blossom Dashboard - v5.0", pageName: "Dashboard" });
  }
}

import * as React from "react";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { UitkSlider } from "uitk-react-slider";
import { SliderComponentProps } from "../typings";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

export const Slider = withStores("propertyFilters")(
  observer((props: SliderComponentProps) => {
    const track = useClickTracker();
    const { filterName, option, propertyFilters, moduleName, min, max, step, subLabel } = props;

    const [range, setRange] = React.useState(option.range);
    React.useEffect(() => {
      setRange(option.range);
    }, [option.range]);

    const onChange = (min: number, max: number) => setRange({ min, max });

    const onHandleAfterChange = () => {
      const isSelected = propertyFilters.isSelected(filterName, option.optionValue ?? "");
      const rfrr = `${moduleName}.${filterName}.${option.optionValue}.${!isSelected}.click`;
      const linkName = "RFRR Action Link";
      track(rfrr, linkName);

      propertyFilters.setFilterRange(filterName, option.optionValue ?? "", range!);
    };

    const formatValueForDisplay = (value: number): string => {
      return `${subLabel}${value}`;
    };

    const formatSecondaryValueForDisplay = (value: number): string => {
      const displayValue = value === 0 ? `${subLabel}${option?.range?.max}+` : `${subLabel}${value}`;

      return value === max ? `${displayValue}+` : displayValue;
    };

    return (
      <UitkSlider
        type="dual"
        min={min || 0}
        max={max || 0}
        name={filterName}
        step={step}
        lowerValue={range?.min || 0}
        upperValue={Math.max(range?.max || 0, step || 0)}
        id={`${filterName}-slider`}
        handleChange={onChange}
        handleAfterChange={onHandleAfterChange}
        formatValueForDisplay={formatValueForDisplay}
        formatSecondaryValueForDisplay={formatSecondaryValueForDisplay}
        gap={0}
      />
    );
  })
);

import { CompositionStore } from "./CompositionStore";
import { StaticMapStore } from "stores/staticMap/StaticMapStore";
import { FlexModuleModelStore } from "src/stores/FlexModuleModelStore";
import { Logger } from "bernie-logger";
import { SerializedData } from "bernie-core";
import {
  ExperienceComposition,
  ExtendedContextStore,
  FlexViewModelResponse,
} from "typings/flexFramework/FlexDefinitions";

export class ClientCompositionStore extends CompositionStore {
  public constructor(state?: SerializedData, logger?: Logger, config?: any) {
    /* istanbul ignore next */
    super(state, logger, config);
  }

  public setup(
    bffResponse: FlexViewModelResponse | undefined,
    experienceComposition: ExperienceComposition | undefined,
    context: ExtendedContextStore,
    flexModuleModelStore: FlexModuleModelStore,
    mapStore: StaticMapStore
  ) {
    super.setup(bffResponse, experienceComposition, context, flexModuleModelStore, mapStore);
  }
}

import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { action, observable, makeObservable } from "mobx";
import { Logger, NOOP_LOGGER } from "bernie-logger";
import { FlexComponent, FlexComponentModel } from "src/typings/flexFramework/FlexComponent";
import { getNameFromFlexComponent } from "src/stores/CompositionStore";

export interface FlexModuleModel {
  type: string;
  fmTitleId: string | null;
  model: object;
  flexComponentRef?: FlexComponent; // Temporary workaround to avoid serializing model in composition-path
}

/**
 * Store that contains Flex Microservice model responses
 * returned by compositor in as a Map, accessible by module id
 *
 * Only populated through template-rendering-path.
 */
export class FlexModuleModelStore extends Store {
  public modelMap: Map<string, FlexModuleModel>; // this is a Map because a plain object is not as observable

  private modelMapAsObject: object;

  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
    makeObservable(this, {
      modelMap: observable,
      updateModelMap: action,
    });
    this.modelMap = new Map();
  }

  public hydrate(data: SerializedData): void {
    if (!data?.modelMapAsObject) {
      return;
    }

    // Map isn't serializable: convert modelMapAsObject back to Map
    Object.getOwnPropertyNames(data.modelMapAsObject).forEach((key) => {
      this.modelMap.set(key, data.modelMapAsObject[key]);
    });
  }

  public findFirstByType(type: string): FlexModuleModel | undefined {
    return [...this.modelMap.values()].find((flexModuleModel) => flexModuleModel.type === type);
  }

  public get(id: string) {
    const item = this.modelMap.get(id);

    if (!item) {
      return null;
    }

    return item;
  }

  public getModel(id: string) {
    const item = this.get(id);

    if (item?.model) {
      const model = item.model as FlexComponentModel;
      if (model.error || model.empty) {
        return null;
      }
      return item.model;
    }

    return null;
  }

  public isPopulated(id: string) {
    const model = this.getModel(id) as FlexComponentModel | null;

    return !(!model || model.empty || model.ajaxOnly);
  }

  public updateModelMap(id: string, flexModuleModel: FlexModuleModel) {
    if (!id) {
      return;
    }

    this.modelMap.set(id, flexModuleModel);
  }

  public toJSON(): SerializedData {
    // Map isn't serializable: convert modelMap to modelMapAsObject
    this.modelMapAsObject = {};
    this.modelMap.forEach((value, key) => {
      const { flexComponentRef, ...importantThings } = value;

      // Temporary workaround to avoid serializing model in composition-path if module is using this store
      // TODO: Clean up when 100% in FlexTemplateRenderer
      // includes hack to exclude hotels and wizard module's model from deletion as it causes a strange bug during hydration
      if (flexComponentRef) {
        const componentName = getNameFromFlexComponent(flexComponentRef);

        if (
          !flexComponentRef.ajaxOnly &&
          !flexComponentRef.clientSideOnly &&
          componentName !== "hotels" &&
          componentName !== "maps" &&
          componentName !== "wizard-flight-pwa" &&
          componentName !== "wizard-hotel-pwa-v2" &&
          componentName !== "wizard-cruise-pwa" &&
          componentName !== "wizard-package-pwa" &&
          componentName !== "wizard-thirdPartyPackage-pwa" &&
          componentName !== "wizard-car-pwa" &&
          componentName !== "wizard-gt-pwa" &&
          componentName !== "wizard-vr-pwa" &&
          componentName !== "wizard-externalLinkTab-pwa" &&
          componentName !== "wizard-lx-pwa" &&
          componentName !== "wizard-hotel-pwa" &&
          componentName !== "te-neighborhood" &&
          componentName !== "region"
        ) {
          flexComponentRef.model = {};
        }
      }

      // @ts-ignore
      this.modelMapAsObject[key] = importantThings;
    });

    return super.toJSON(["modelMap"]);
  }
}

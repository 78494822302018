import { Store } from "bernie-plugin-mobx";
import { serializable, SerializedData } from "bernie-core";
import { action, observable, makeObservable } from "mobx";
import {
  AjaxParams,
  Composition,
  ExperienceComposition,
  ExtendedContextStore,
  FlexViewModelResponse,
  FlexContext,
  LoggerOptions,
  ModuleAjaxParams,
} from "typings/flexFramework/FlexDefinitions";
import * as fetch from "isomorphic-fetch";
import { safeResponseToJson } from "components/utility/FetchUtils";
import { Logger, NOOP_LOGGER } from "bernie-logger";
import { FlexComponent } from "typings/flexFramework/FlexComponent";
import { FAILED_MODULE_COMPOSITION_FETCH, FAILED_REACTIVE_MODULES_COMPOSITION_FETCH } from "config/systemEvents";
import { getQueryParamValue } from "src/components/utility/UrlUtils";
import { StaticMapStore } from "stores/staticMap/StaticMapStore";
import { FlexModuleModel, FlexModuleModelStore } from "src/stores/FlexModuleModelStore";
import { getNameFromFlexComponent } from "./getNameFromFlexComponent";

/**
 * Mobx store that holds the composition data.
 * - Fetch the composition on the template rendering path
 * - publish analytics on the template rendering path
 * - Handle scenes
 * - Fetch ajax module data
 * - Populate moduleModelStore with composition data (when ready)
 */
@serializable
export class CompositionStore extends Store {
  // Composition is serialized only in composition-path
  public composition?: Composition;

  // Serialized
  public pageHeading: string | null;

  public pageSubHeadline: string | null;

  public title: string;

  public templateId: string;

  public canonicalPath: string;

  @serializable.ignore
  private flexModuleModelStore: FlexModuleModelStore;

  @serializable.ignore
  private flexContext?: FlexContext;

  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER, config: any = {}) {
    super(state, logger);
    makeObservable(this, {
      composition: observable,
      setup: action,
      setupFromExperienceComposition: action,
      populateFlexModuleModelStoreFromComposition: action,
      populateFlexModuleModelStoreFromExperienceComposition: action,
    });
  }

  /* istandbul ignore next */
  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  /**
   * Initializes CompositionStore with data from other stores. Called from controller
   * @param  {ViewModelResponse|FlexBffTemplateResponse} bffResponse
   * @param experienceComposition
   * @param  {ExtendedContextStore} context
   * @param  {FlexModuleModelStore} flexModuleModelStore
   * @param  {StaticMapStore} mapStore
   * @returns void
   */
  public setup(
    bffResponse: FlexViewModelResponse | undefined,
    experienceComposition: ExperienceComposition | undefined,
    context: ExtendedContextStore,
    flexModuleModelStore: FlexModuleModelStore,
    mapStore: StaticMapStore
  ) {
    this.flexModuleModelStore = flexModuleModelStore;

    if (!bffResponse) {
      return;
    }

    this.templateId = bffResponse.templateId;
    this.canonicalPath = bffResponse.canonicalPath;
    this.flexContext = bffResponse.flexContext;

    const { composition } = bffResponse;
    // Do additional things if we are typical composition path
    // TODO: delete this when fully hybrid
    if (composition) {
      this.composition = composition;
      this.pageHeading = composition.pageHeading;
      this.pageSubHeadline = composition.pageSubHeadline;
      this.title = composition.title;
      this.injectInterestFreePayLater(this.composition);
      this.buildStaticMapImgUrl(this.composition, context, mapStore);
    }

    if (experienceComposition) {
      this.setupFromExperienceComposition(experienceComposition, context, mapStore);
    }
  }

  public setupFromExperienceComposition(
    experienceComposition: ExperienceComposition,
    context: ExtendedContextStore,
    mapStore: StaticMapStore
  ) {
    const seoModel = Object.values(experienceComposition.models).find((model) => model.type === "seo");
    if (seoModel) {
      this.title = seoModel.model.title;
      this.pageHeading = seoModel.model.header;
      this.pageSubHeadline = seoModel.model.subHeader;
    }

    // This can be implemented as a customer segment in experience-manager although it doesn't seem to be used anymore
    // this.injectInterestFreePayLater(experienceComposition);
    this.buildStaticMapImgUrlFromExperience(experienceComposition, context, mapStore);
    this.populateFlexModuleModelStoreFromExperienceComposition(experienceComposition);
  }

  /**
   * Populates FlexModuleModelStore from the composition. Necessary to fully reconstruct state after hydration as some data is omitted from serialization. See CompositionStore.toJSON() for context
   * @param {Composition} composition
   */
  public populateFlexModuleModelStoreFromComposition(composition?: Composition) {
    if (!composition?.page.regionList) {
      return;
    }

    composition?.page.regionList.forEach((region) => {
      this.updateModelMapFromFlexComponent(region);
    });
  }

  public populateFlexModuleModelStoreFromExperienceComposition(experienceComposition?: ExperienceComposition) {
    if (!experienceComposition?.models) {
      return;
    }

    Object.entries(experienceComposition.models).forEach(([fmId, experienceComponent]) => {
      this.flexModuleModelStore.updateModelMap(
        fmId,
        this.buildFlexModuleModel({
          type: experienceComponent.type,
          fmTitleId: experienceComponent.fmTitleId,
          model: experienceComponent.model,
        })
      );
    });
  }

  /**
   * creates NewBlossomComponent equivalent of provided FlexComponent
   * @param  {FlexComponent} flexComponent
   * @returns NewBlossomComponent|undefined
   */
  private updateModelMapFromFlexComponent(flexComponent: FlexComponent) {
    if (!flexComponent) {
      return;
    }

    // ensure all components have ids
    // hoist id, view, ajaxOnly, clientSideOnly up to flexComponent so model can be deleted after adding to FlexModuleModelStore
    flexComponent.id =
      flexComponent.id ??
      flexComponent.model?.id ??
      getComponentIdFallback(getNameFromFlexComponent(flexComponent), flexComponent.fmId);
    flexComponent.view = flexComponent.model?.view;
    flexComponent.ajaxOnly = flexComponent.model?.ajaxOnly;
    flexComponent.clientSideOnly = flexComponent.model?.clientSideOnly;

    this.flexModuleModelStore.updateModelMap(flexComponent.id, {
      ...this.buildFlexModuleModel({
        type: getNameFromFlexComponent(flexComponent),
        fmTitleId: flexComponent.fmTitleId ? `${flexComponent.fmTitleId}` : null,
        model: flexComponent.model,
      }),
      flexComponentRef: flexComponent,
    });

    [...(flexComponent.modules || []), ...(flexComponent.groups || []), ...(flexComponent.children || [])].forEach(
      (child) => {
        this.updateModelMapFromFlexComponent(child);
      }
    );
  }

  private buildFlexModuleModel = (input: { type: string; fmTitleId: string | null; model: any }): FlexModuleModel => ({
    type: input.type,
    fmTitleId: input.fmTitleId,
    model: input.model,
  });

  public buildStaticMapImgUrl = (composition: Composition, context: ExtendedContextStore, mapStore: StaticMapStore) => {
    if (!composition?.page?.regionList) {
      return;
    }

    const hotelsModel = findFlexComponent(
      composition.page.regionList,
      (flexComponent) => flexComponent?.id === "hotels-1"
    );

    mapStore.generateGoogleStaticMapSrcNoPins(context);

    if (hotelsModel) {
      mapStore.generateGoogleStaticMapSrcForHotels(context, hotelsModel.model.hotels);
    }
  };

  public buildStaticMapImgUrlFromExperience = (
    experienceComposition: ExperienceComposition,
    context: ExtendedContextStore,
    mapStore: StaticMapStore
  ) => {
    if (!experienceComposition?.models) {
      return;
    }

    const hotelsModel = Object.values(experienceComposition.models).find((component) => component.type === "hotels");

    mapStore.generateGoogleStaticMapSrcNoPins(context);

    if (hotelsModel) {
      mapStore.generateGoogleStaticMapSrcForHotels(context, hotelsModel.model.hotels);
    }
  };

  /**
   * fetches updated model from FlexBFF for module indicated in options
   * @param options
   * @param loggerOptions [optional]
   * @returns Promise<void>
   * @private
   * @memberof CompositionStore
   */
  private fetchModule = async (options: ModuleAjaxParams, loggerOptions: LoggerOptions) => {
    if (!options.fmId) {
      return Promise.reject("options.fmId is required");
    }

    options.url = this.buildUrlQueryParam();
    options.cache = options.cache ?? "false";
    options.epoch = options.epoch ?? Date.now().toString();
    options.viewName = options.viewName ?? "json";
    options.clientId = options.clientId ?? "blossom";
    options.userAttributes = options.userAttributes ?? "blossom";
    options.path = "/lpt/flex/ajax";
    options.tId = options.tId ?? this.templateId;

    const endpointUrl = this.buildAjaxEndpointUrl(options, loggerOptions);

    if (endpointUrl) {
      return fetch(endpointUrl, { method: "GET" }).then(safeResponseToJson);
    }
  };

  /**
   * fetches updated model from FlexBFF for module indicated in options
   * and updates the composition and FlexModuleModelStore with the response
   *
   * @param options
   * @param loggerOptions [optional]
   * @returns Promise<void>
   */
  public updateModule = async (options: ModuleAjaxParams, loggerOptions?: LoggerOptions) => {
    const _loggerOptions = loggerOptions ?? {
      event: FAILED_MODULE_COMPOSITION_FETCH,
      args: `Error when fetching composition for module due to missing values for templateId=${this.templateId}, fmId=${options.fmId}`,
    };

    return this.fetchModule(options, _loggerOptions)
      .then((moduleBody) => {
        if (moduleBody) {
          this.updateModuleModels(
            options.fmId,
            this.buildFlexModuleModel({
              type: moduleBody.name,
              fmTitleId: moduleBody.fmTitleId,
              model: moduleBody,
            })
          );
        }

        return Promise.resolve({
          status: 200,
        });
      })
      .catch((err) => {
        this.logger.logEvent(_loggerOptions.event, _loggerOptions.args, err);

        return Promise.resolve({
          status: 500,
        });
      });
  };

  /**
   * Fetches modules related to a scene change from the FlexBFF
   * and updates the composition and FlexModuleModelStore with the response
   *
   * @param scene a string id representing the scene attribute value
   * @returns Promise<void>
   */
  public changeScene = async (scene: string) => {
    return this.fetchScene(scene)
      .then((components: FlexComponent[]) => {
        components.forEach((c) => this.updateModuleModels(c.fmId, this.buildFlexModuleModel(c.model)));
      })
      .catch((err) => {
        this.logger.logEvent(
          FAILED_MODULE_COMPOSITION_FETCH,
          "Unexpected error when fetching composition for scene",
          err
        );
      });
  };

  /**
   * Fetches modules related to a scene change from the FlexBFF
   *
   * @param sceneId a string id representing the scene attribute value
   * @returns Promise<void>
   */
  private fetchScene = async (sceneId: string) => {
    if (!sceneId) {
      return Promise.reject("sceneId is required");
    }

    const endpointUrl = this.buildAjaxEndpointUrl(
      {
        tId: this.templateId,
        scene: sceneId,
        path: "/lpt/flex/reactive",
      },
      {
        event: FAILED_REACTIVE_MODULES_COMPOSITION_FETCH,
        args: `Error when fetching reactive modules for scene due to missing values for templateId=${this.templateId}, scene=${sceneId}`,
      }
    );

    if (endpointUrl) {
      return fetch(endpointUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.flexContext),
      }).then(safeResponseToJson);
    }
  };

  /**
   * takes the model of the provided FlexComponent and updates the entries in Composition and FlexModuleModelStore indicated by the provided fmId
   *
   * @param fmId the fmId of the component to be updated
   * @param {FlexComponent | any} component a FlexComponent containing the new data
   */
  public updateModuleModels = (fmId: number, flexModuleModel: FlexModuleModel) => {
    if (this.composition) {
      const componentToUpdate = this.findFlexComponentByFmId(fmId);

      if (componentToUpdate && this.flexModuleModelStore) {
        componentToUpdate.model = flexModuleModel.model;
        this.flexModuleModelStore.updateModelMap(componentToUpdate.id, flexModuleModel);
      }
    } else {
      this.flexModuleModelStore.updateModelMap(`${fmId}`, flexModuleModel);
    }
  };

  private buildAjaxEndpointUrl(options: AjaxParams, loggerOptions: LoggerOptions) {
    if (!options.tId || (!options.fmId && !options.scene)) {
      this.logger.logEvent(loggerOptions.event, loggerOptions.args, JSON.stringify(options));

      return;
    }

    let queryParams = "";
    Object.keys(options)
      .filter((key) => options[key as keyof AjaxParams])
      .forEach((key, index) => {
        if (key) {
          queryParams =
            index === 0
              ? `?${key}=${options[key as keyof AjaxParams]}`
              : `${queryParams}&${key}=${options[key as keyof AjaxParams]}`;
        }
      });

    return `${options.path}${queryParams}`;
  }

  private buildUrlQueryParam(): string | undefined {
    if (this.canonicalPath) {
      const tName = getQueryParamValue("tName");
      let urlQueryParam = this.canonicalPath;

      if (this.canonicalPath === "/") {
        urlQueryParam = this.canonicalPath;
      } else if (this.canonicalPath.startsWith("/")) {
        urlQueryParam = this.canonicalPath.substr(1);
      }

      if (tName) {
        urlQueryParam = `${urlQueryParam}?tName=${tName}`;
      }

      return encodeURI(urlQueryParam);
    }

    return undefined;
  }

  // This looks like it is no longer functional and can be removed now
  // Temporary hack to add required EXP_MX (TPID 12) /EXP_BR (TPID 69) Legal & Loyalty Information
  // This assumes that the region is called Wizard and page type is HTG
  private injectInterestFreePayLater = (composition: Composition) => {
    const tpid = this.flexContext?.siteContext.tpid;
    if ((tpid === 12 || tpid === 69) && this.flexContext?.searchContext?.pageType === "Travel-Guide-Hotels") {
      const wizardRegion = composition?.page?.regionList?.filter((region) => region.name === "wizard")[0];

      if (wizardRegion) {
        const messaging = tpid === 12 ? "Hoteles en hasta 18 MSI" : "Hotéis em até 12x sem juros";

        const injectedFlexComponent = {
          model: {
            contentPurpose: "ConfidenceMessage",
            items: [{ text: messaging }],
          },
          type: "Module",
          name: "editorial",
        } as any;

        wizardRegion.modules = [...wizardRegion.modules, injectedFlexComponent];
      }
    }
  };

  /**
   * finds FlexComponent associated with provided fmId, searching entire composition or provided composition fragment
   * @param fmId
   * @param componentsToSearch [optional]
   * @returns FlexComponent | undefined
   * @memberof CompositionStore
   */
  public findFlexComponentByFmId = (fmId: number, componentsToSearch?: FlexComponent[]): FlexComponent | undefined => {
    if (!fmId) {
      return;
    }

    const components = componentsToSearch ?? this.composition?.page.regionList;

    if (!components) {
      return;
    }

    return findFlexComponent(components, (c) => c.fmId === fmId);
  };
}

/**
 * returns first match in provided FlexComponent[] collection which satisfies the provided test, iterating downward through all child components
 * @export
 * @param {FlexComponent[]} componentsToSearch
 * @param {(flexComponent: FlexComponent) => boolean} test
 * @returns {(FlexComponent | undefined)}
 */
export function findFlexComponent(
  componentsToSearch: FlexComponent[],
  test: (flexComponent: FlexComponent) => boolean
): FlexComponent | undefined {
  let result: FlexComponent | undefined;

  for (const component of componentsToSearch) {
    if (test(component)) {
      result = component;
      break;
    }

    const childComponents: FlexComponent[] = [
      ...(component.modules || []),
      ...(component.groups || []),
      ...(component.children || []),
    ];

    if (childComponents) {
      result = findFlexComponent(childComponents, test);

      if (result) {
        break;
      }
    }
  }

  return result;
}

/**
 * returns a string suitable for use as a component id.
 * Takes the form ${componentName}-${index}
 * @param componentName
 * @param index [optional] if not provided will be assigned a random 5 digit number
 */
const getComponentIdFallback = (componentName: string, index?: number): string => {
  index = index ?? Math.round(Math.random() * 100000);

  return `${componentName}-${index}`;
};

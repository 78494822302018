export interface FetchError {
  errorMessage: string;
  body: string;
}

/**
 * Utility function to safely handle `response.json()` when conducting an isomorphic-fetch. On a bad response, it throws a FetchError.
 * @param {Response} response
 * @returns {any | FetchError}
 */
export const safeResponseToJson = (response?: Response): FetchError | Promise<any> => {
  if (!response) {
    throw JSON.stringify({
      errorMessage: "Received invalid response from backend service",
      body: response,
    });
  }

  // Handle 300s/400s/500s
  if (response.status >= 300) {
    throw JSON.stringify({
      errorMessage: `Received invalid statusCode=${response.status} statusText=${response.statusText} from backend service`,
      body: response.body,
    });
  }

  // Otherwise assume it is a good response
  // Try to return json; otherwise the text
  try {
    return response.json();
  } catch (err) {
    return response.text();
  }
};

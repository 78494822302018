import { codeSplit, Controller, FetchOptions, FetchPageOptions } from "bernie-core";
import { PageData } from "bernie-http";
import { AnalyticsStore, PageStore } from "bernie-plugin-mobx";
import { getBexApiContext } from "bernie-context";
import { FlexViewModelStore } from "stores/flexViewModel/FlexViewModelStore";
import { Composition, ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { CookiesStore } from "stores/CookiesStore";
import { StaticMapStore } from "stores/staticMap/StaticMapStore";
import { CompositionStore } from "src/stores/CompositionStore";
import { FlexModuleModelStore } from "src/stores/FlexModuleModelStore";
import { blossomCodeSplitter } from "src/components/flexFramework/CodeSplitter/BlossomCodeSplitter";

interface WindowWithState extends Window {
  __PLUGIN_STATE__: {
    controllers: {
      stores: {
        compositionStore: CompositionStore;
      };
    };
  };
}

declare let window: WindowWithState;

interface FlexFetchPageOptions extends FetchPageOptions {
  context: ExtendedContextStore;
}

export class MockModuleController implements Controller {
  public pageId = "mockModuleLanding";

  public path = "/render/module/test";

  public routeName = "blossom-MockLandingPage";

  public bundles = ["blossom-MockLandingPage"];

  public exact = false;

  public component = codeSplit(async () => {
    const routeComponent = import(/* webpackChunkName: "blossom-MockLandingPage" */ "views/MockFlexPage") as any;
    let composition: Composition | undefined;

    if (typeof window !== "undefined") {
      const serializedStores = window.__PLUGIN_STATE__.controllers.stores;
      composition = serializedStores.compositionStore?.composition;
    }

    await blossomCodeSplitter.loadRequiredCodeSplitBundles({ composition });

    return routeComponent;
  });

  public fetch(options: FetchOptions): Promise<any> {
    const { stores, request } = options;
    const flexViewModelStore = stores && stores.get<FlexViewModelStore>("flexViewModel");
    const pageStore = stores && stores.get<PageStore>("page");
    const contextStore = stores && stores.get<ExtendedContextStore>("context");
    const cookiesStore = stores && stores.get<CookiesStore>("cookies");
    const staticMapStore = stores && stores.get<StaticMapStore>("staticMap");
    const compositionStore = stores && stores.get<CompositionStore>("compositionStore");
    const flexModuleModelStore = stores && stores.get<FlexModuleModelStore>("flexModuleModelStore");
    const analyticsStore = stores && stores.get<AnalyticsStore>("analytics");

    // Get cookie string for wizard
    cookiesStore.cookie = (request && request.headers && request.headers.cookie) || "";

    const viewModelResponse = contextStore.viewModelResponse!;

    flexViewModelStore.setupFlexViewModelStore(viewModelResponse, pageStore, contextStore, analyticsStore);
    compositionStore.setup(viewModelResponse, undefined, contextStore, flexModuleModelStore, staticMapStore);

    return Promise.resolve({});
  }

  public async fetchPageData(options: FlexFetchPageOptions): Promise<PageData> {
    const viewModelResponse = options.context.viewModelResponse!;
    const additionalScripts = blossomCodeSplitter.getRequiredWebpackChunkNames({
      composition: viewModelResponse.composition,
    });

    const bexApiContext = getBexApiContext(options.context);

    const seoUtils = (await import(/* webpackChunkName: "SeoUtils" */ "utils/seo/SeoUtils")).default;
    const seoMetadata = (await seoUtils.buildPageDataWithSeoMetaDataFromViewModel(
      viewModelResponse,
      bexApiContext
    )) || {
      title: viewModelResponse.composition.title,
    };

    // Basically giving a list of webpackChunkNames that we want
    return {
      ...seoMetadata,
      assetsToIncludeFilter: (assetKey: string) => additionalScripts.includes(assetKey),
    };
  }
}

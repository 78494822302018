import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { observable, action, makeObservable } from "mobx";
import { formatText } from "bernie-l10n";
import { Logger } from "bernie-logger";
import { PillId } from "components/flexComponents/Packages/utils/PackagesEnum";
import {
  tripLengthPillFilter,
  starRatingPillFilter,
  flightClassPillFilter,
} from "components/flexComponents/Packages/utils/PillFiltersConfig";

export class PackagePillStore extends Store {
  public tripLengthName: string;
  public starRatingName: string;
  public flightClassName: string;
  public tripLengthActiveState: boolean;
  public starRatingActiveState: boolean;
  public flightClassActiveState: boolean;

  public static DEFAULTS = {
    tripLengthName: "",
    starRatingName: "",
    flightClassName: "",
    tripLengthActiveState: false,
    starRatingActiveState: false,
    flightClassActiveState: false,
  };

  public constructor(state: SerializedData, logger: Logger) {
    super(state, logger);

    makeObservable(this, {
      tripLengthName: observable,
      starRatingName: observable,
      flightClassName: observable,
      tripLengthActiveState: observable,
      starRatingActiveState: observable,
      flightClassActiveState: observable,
      reset: action,
      updatePill: action,
    });
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
    this.reset();
  }

  public reset() {
    this.resetPillNames();
    this.resetPillActiveState();
  }

  public updatePill(pillId: string, newPillName: string) {
    switch (pillId) {
      case PillId.TRIP_LENGTH:
        this.tripLengthName = newPillName;
        this.tripLengthActiveState = true;
        break;
      case PillId.STAR_RATING:
        this.starRatingName = newPillName;
        this.starRatingActiveState = true;
        break;
      case PillId.FLIGHT_CLASS:
        this.flightClassName = newPillName;
        this.flightClassActiveState = true;
        break;
      default:
        break;
    }
  }

  public getPillName(pillId: string): string {
    switch (pillId) {
      case PillId.TRIP_LENGTH:
        return this.tripLengthName;
      case PillId.STAR_RATING:
        return this.starRatingName;
      case PillId.FLIGHT_CLASS:
        return this.flightClassName;
      default:
        return "";
    }
  }

  public getPillActiveState(pillId: string): boolean {
    switch (pillId) {
      case PillId.TRIP_LENGTH:
        return this.tripLengthActiveState;
      case PillId.STAR_RATING:
        return this.starRatingActiveState;
      case PillId.FLIGHT_CLASS:
        return this.flightClassActiveState;
      default:
        return false;
    }
  }

  private resetPillActiveState() {
    this.tripLengthActiveState = false;
    this.starRatingActiveState = false;
    this.flightClassActiveState = false;
  }

  private resetPillNames() {
    [tripLengthPillFilter, starRatingPillFilter, flightClassPillFilter].map((pill) => {
      switch (pill.id) {
        case PillId.TRIP_LENGTH:
          this.tripLengthName = formatText(pill.name);
          break;
        case PillId.STAR_RATING:
          this.starRatingName = formatText(pill.name);
          break;
        case PillId.FLIGHT_CLASS:
          this.flightClassName = formatText(pill.name);
          break;
      }
    });
  }
}

import * as React from "react";
import { withStores } from "src/stores";
import { PropertyFiltersComponentProps, PropertyFiltersComponentGroupProps } from "../../typings";
import { PropertyFiltersStore } from "src/stores/PropertyFiltersStore";
import { getSelectedRange } from "./getSelectedRange";
import { Options } from "src/components/flexComponents/PropertyFilters/typings";
import { Checkbox } from "../../components/Checkbox";
import { UitkCheckboxGroup } from "uitk-react-checkbox";
import { observer } from "mobx-react";

type OnChange = PropertyFiltersComponentProps["onChange"];

const Range = (range: Options["range"]) => ({
  isIncluded: (otherRange: Options["range"]) => {
    const hasLargerMinRange = range?.min! <= otherRange?.min!;
    const hasSmallerMaxRange = Boolean(otherRange?.max) && otherRange?.max! <= range?.max!;
    const isEveryMaxRangesIncluded = range?.max === null;

    return hasLargerMinRange && (isEveryMaxRangesIncluded || hasSmallerMaxRange);
  },
});

/**
 *
 * @param { Options } selectedOption
 *
 * Creates an option comparition method according to isSelected value of the selected option.
 * If it's selected all option with a smaller range will return true, if it's not so all the larger options
 *
 */
const buildDependentOptionComparator = (selectedOption: Options, options: Options[]) => {
  return (option: Options) => {
    if (selectedOption.isSelected) {
      const selectedRange = getSelectedRange(options);
      return Range(selectedRange).isIncluded(option.range);
    }

    return option.isSelected && option.disabled;
  };
};

/**
 *
 * @param { OnChange } currentHandle
 * @param { PropertyFiltersStore } propertyFilters
 *
 * Create a option handler that propagate the change on all related options,
 * and persist the onChange handler if the user provide one.
 *
 */
const createHandle = (propertyFilters: PropertyFiltersStore): OnChange => {
  return (filterName, selectedOption) => {
    const options = propertyFilters.getOptions(filterName);
    const shouldUpdateOption = buildDependentOptionComparator(selectedOption, options);

    options.filter(shouldUpdateOption).forEach(({ optionValue }, index, { length }) => {
      const isInBetweenRanges = index > 0 && index < length - 1;
      const isDisabled = selectedOption.isSelected && isInBetweenRanges;

      propertyFilters.setFilterStatus(filterName, optionValue!, selectedOption.isSelected);
      propertyFilters.setDisabled(filterName, optionValue!, isDisabled);
    });
  };
};

export const RangeCheckboxGroup = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentGroupProps) => {
    const { filterLabel, filterName, options, propertyFilters, moduleName } = props;

    return (
      <UitkCheckboxGroup legendLabel={filterLabel} group={filterName}>
        {options.map((option, index) => (
          <Checkbox
            key={`${option.optionValue}-${index}`}
            option={option}
            filterName={filterName}
            moduleName={moduleName}
            onChange={createHandle(propertyFilters)}
          />
        ))}
      </UitkCheckboxGroup>
    );
  })
);

export default RangeCheckboxGroup;

import { Logger } from "bernie-logger";

import { fetchHotwireApi, HotwireApiConfig, HotwireFetchMethod } from "components/utility/hotwire/HotwireApiUtil";
import { safeResponseToJson } from "components/utility/FetchUtils";
import { setStorageItem } from "components/utility/hotwire/HotwireStorageUtil";
import { FAILED_HOTW_CID_IDENTIFY } from "config/systemEvents";

// Only apply to Hotwire subdomains
const ALLOWED_HOSTS = ["www.hotwire.com", "hotwire.com"];
const CID_PARAM = "cid";

// 1 year in milliseconds
const CID_MAX_AGE_MS = 60 * 60 * 24 * 365 * 1000;
const CID_KEY = "mktg.gccid._";

interface CustomerIdentifyResponse {
  customerId?: number;
}

/**
 * Handles the Hotwire guest customer ID "cid" marketing query parameter.
 *
 * This parameter sent by Hotwire Marketing e-mails needs to be decoded and stored in the browser.
 */
export const handleGuestCustomerId = function (customerIdentifyConfig: HotwireApiConfig, logger: Logger) {
  const parsedURL = new URL(window.location.href);

  if (ALLOWED_HOSTS.includes(parsedURL.host)) {
    const cid = parsedURL.searchParams.get(CID_PARAM);

    if (cid) {
      fetchHotwireApi(customerIdentifyConfig, {
        method: HotwireFetchMethod.GET,
        query: { cid },
      })
        .then(safeResponseToJson)
        .then((data?: CustomerIdentifyResponse) => {
          if (data?.customerId) {
            setStorageItem(CID_KEY, data.customerId.toString(), CID_MAX_AGE_MS);
          }
        })
        .catch((error) => logger.logEvent(FAILED_HOTW_CID_IDENTIFY, error));
    }
  }
};

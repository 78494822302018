import { Options } from "src/components/flexComponents/PropertyFilters/typings";

export const getSelectedRange = (options: Options[]): Options["range"] | undefined => {
  let selectedRange: Options["range"] | undefined;

  options
    .filter((option) => option.isSelected)
    .forEach((option) => {
      const range = option.range;

      if (!range) return;

      if (!selectedRange) {
        selectedRange = {
          min: range.min,
          max: range.max,
          __typename: "FilterOptionRange",
        };
        return;
      }

      const min = (selectedRange.min || 0) < (range.min || 0) ? selectedRange.min : range.min;
      const max = (selectedRange.max || 0) > (range.max || 0) ? selectedRange.max : range.max;
      const hasMax = Boolean(range.max);

      selectedRange.min = min;
      selectedRange.max = hasMax ? max : null;
    });

  return selectedRange;
};

export default getSelectedRange;

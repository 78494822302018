import { FlexComponent } from "typings/flexFramework/FlexComponent";

export const getNameFromFlexComponent = (flexComponent: FlexComponent): string => {
  if (flexComponent.type === "Region") {
    return "region";
  }
  if (flexComponent.type === "Module") {
    return flexComponent.name;
  }
  // probably a TAB
  return "tab";
};

import { observer } from "mobx-react";
import * as React from "react";
import { withStores } from "src/stores";
import { UitkButtonToggleGroup } from "uitk-react-button-toggle";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { ButtonToggle } from "../components/ButtonToggle";
import { PropertyFiltersComponentGroupProps } from "../typings";

export const ButtonToggleGroup = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentGroupProps) => {
    const { filterName, options, moduleName, propertyFilters, selectFirstOptionByDefault } = props;
    const firstOptionValue = selectFirstOptionByDefault ? options[0].optionValue : undefined;

    return (
      <UitkLayoutFlex wrap="wrap">
        <UitkLayoutFlexItem>
          <UitkButtonToggleGroup wrapButtons>
            {options.map((opt, index) => {
              let isFirstOptionSelected = selectFirstOptionByDefault && opt.optionValue === firstOptionValue;
              return (
                <ButtonToggle
                  key={`${opt.optionValue}-${index}`}
                  option={opt}
                  filterName={filterName}
                  moduleName={moduleName}
                  isSelected={propertyFilters.isSelected(filterName, opt.optionValue!) || isFirstOptionSelected}
                />
              );
            })}
          </UitkButtonToggleGroup>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    );
  })
);

export default ButtonToggleGroup;

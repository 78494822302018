import { SerializedData } from "bernie-core";
import { Store } from "bernie-plugin-mobx";
import { Logger } from "bernie-logger";
import { FlexViewModelStore } from "stores/flexViewModel/FlexViewModelStore";
import { AnalyticsPayload, TrackingContext, FlexContext, Location } from "typings/flexFramework/FlexDefinitions";

export class ClientFlexViewModelStore extends Store implements FlexViewModelStore {
  public trackingContext: TrackingContext;

  public flexContext: FlexContext;

  public analyticsPayload: AnalyticsPayload;

  public structuredData: string;

  public userLocation: Location;

  public constructor(state: SerializedData, logger: Logger) {
    super(state, logger);
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  public setupFlexViewModelStore() {
    // do nothing
  }
}

import { isVariantEnabled } from "components/utility/experiment";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

enum HotelSearchParam {
  STAY_LENGTH = "stayLength",
  REGION_ID = "regionId",
  DAYS_IN_FUTURE = "daysInFuture",
  PWA_OVERLAY = "pwaOverlay",
  LANG_ID = "langId",
  SKIP_SERVER_FETCH = "skipServerFetch",
  START_DATE = "startDate",
  END_DATE = "endDate",
  PINNED_HOTEL_ID = "PinnedHotelId",
  HAD_PINNED_HOTEL = "HadPinnedHotel",
  SELECTED = "selected",
  EXPAND_FORM = "expandForm",
  SORT = "sort",
  PRICE = "price",
}

/**
 * Builds your typical Hotel-Search link using the builder pattern.
 * More info: @see https://paper.dropbox.com/doc/Blossom-Helper-Components--AvovFRbxESq0qgrtJStxY6HeAg-ph76HYqc28mEws3uEIvKc#:uid=285688582701111758947434&h2=HotelSearchLinkBuilder
 *
 * Example usage:
 * new HotelSearchLinkBuilder().withRegionId("178288").withMapOpen().build();
 *
 * This will build `/Hotel-Search?regionId=178288&pwaOveray=map`
 *
 * Note: By default, SRP uses today and tomorrow as default start/end dates.
 *
 * Also see examples in:
 * @see PropertyFiltersContext.buildLinkToSRP
 * @see useFullscreenOpen.sendUserToSRP
 * @see MapPoiSheet
 * @see Deal
 *
 */
export class HotelSearchLinkBuilder {
  private readonly initialLink: string;

  private queryParams: { [key: string]: string } = {};

  // Optionally pass in an initial link with already defined query params
  public constructor(initialLink = "/Hotel-Search") {
    this.initialLink = initialLink;
  }

  // Optionally needs context for the Blossom_Skip_Server_Fetch TnL (35852)
  public build(context?: ExtendedContextStore) {
    if (isVariantEnabled(context, "Blossom_Skip_Server_Fetch")) {
      this.withSkipServerFetch();
    }

    // Fast-exit if no query params to add
    if (Object.entries(this.queryParams).length === 0) {
      return this.initialLink;
    }

    // Need to determine if we want ? or &
    const connector = this.initialLink.includes("?") ? "&" : "?";

    return `${this.initialLink}${connector}${this.buildQueryParamString()}`;
  }

  // e.g. given { joud: chataoui, carlos: giraldo }
  // returns "joud=chataoui&carlos=giraldo"
  private buildQueryParamString() {
    return Object.entries(this.queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }

  private addQueryParam(key: string, value?: string) {
    if (!value) return this;

    this.queryParams[key] = value;

    return this;
  }

  public has(key: string) {
    return !!this.queryParams[key];
  }

  // Number of days of hotel stay
  public withStayLength(length = 1) {
    return this.addQueryParam(HotelSearchParam.STAY_LENGTH, String(length));
  }

  // Represents a start date N days in the future from today
  public withDaysInFuture(length = 30) {
    return this.addQueryParam(HotelSearchParam.DAYS_IN_FUTURE, String(length));
  }

  public withRegionId(regionId?: string) {
    return this.addQueryParam(HotelSearchParam.REGION_ID, regionId);
  }

  public withMapOpen() {
    return this.addQueryParam(HotelSearchParam.PWA_OVERLAY, "map");
  }

  public withLangId(langId: string) {
    return this.addQueryParam(HotelSearchParam.LANG_ID, langId);
  }

  // Assumes a format of yyyy-mm-dd
  public withStartDate(date?: string) {
    return this.addQueryParam(HotelSearchParam.START_DATE, date);
  }

  // Assumes a format of yyyy-mm-dd
  public withEndDate(date?: string) {
    return this.addQueryParam(HotelSearchParam.END_DATE, date);
  }

  // For pinned SRP
  public withPinnedHotelId(id: string) {
    this.addQueryParam(HotelSearchParam.HAD_PINNED_HOTEL, "true");
    this.addQueryParam(HotelSearchParam.SELECTED, id);

    return this.addQueryParam(HotelSearchParam.PINNED_HOTEL_ID, id);
  }

  // Experimental flag to skip server-side rendering on SRP
  public withSkipServerFetch() {
    return this.addQueryParam(HotelSearchParam.SKIP_SERVER_FETCH, "true");
  }

  public withSorting(sort: string) {
    return this.addQueryParam(HotelSearchParam.SORT, sort);
  }

  public withExpandForm() {
    return this.addQueryParam(HotelSearchParam.EXPAND_FORM, "true");
  }

  public withPrice(price?: string) {
    const value = price ? price.split(",").join(`&${HotelSearchParam.PRICE}=`) : "";
    return this.addQueryParam(HotelSearchParam.PRICE, value);
  }

  // We assume that having multiple option values are appended using ','
  // e.g. amenities=POOL,ALL_INCLUSIVE,PET_FRIENDLY
  public withFilter(filterName: string, optionValue?: string) {
    // We can only have search one region id at a time
    if (filterName === HotelSearchParam.REGION_ID) {
      return this.withRegionId(optionValue);
    }

    if (filterName === HotelSearchParam.PRICE) {
      return this.withPrice(optionValue);
    }

    if (this.has(filterName)) {
      return this.addQueryParam(filterName, `${this.queryParams[filterName]},${optionValue}`);
    }

    return this.addQueryParam(filterName, optionValue);
  }

  public merge(builder: HotelSearchLinkBuilder) {
    const newQueryParams = builder.queryParams;

    this.queryParams = Object.keys(newQueryParams).reduce((queryParams, key) => {
      if (queryParams[key]) {
        return {
          ...queryParams,
          [key]: `${queryParams[key]},${newQueryParams[key]}`,
        };
      }

      return {
        ...queryParams,
        [key]: newQueryParams[key],
      };
    }, this.queryParams);

    return this;
  }
}

export const locKeys: any = {
  directFlightsLabel: "wizard.package.directFlight.label",
  flightHotelLabel: "wizard.package.FlightHotel.label",
  flightHotelCarLabel: "wizard.package.FlightHotelCar.label",
  flightCarLabel: "wizard.package.FlightCar.label",
  hotelCarLabel: "wizard.package.HotelCar.label",
  flightOriginLabel: "wizard.package.flight.origin.label",
  flightDestinationLabel: "wizard.package.flight.destination.label",
  hotelSectionLabel: "wizard.package.hotel.section.label",
  staySomewhereElseDestinationLabel: "wizard.package.fssse.hotel.label",
  staySomewhereElseDestinationPlaceholder: "wizard.package.fssse.hotel.placeholder",
  partialStayLabel: "wizard.package.partialStay.label",
  partialStayAccommodationLabel: "wizard.package.partialStay.accommodation.checkbox.label",
  preferredClassEconomyLabel: "wizard.flight.preferredClass.economy.text",
  flightRoundTripText: "wizard.flight.roundTrip.text",
  flightOneWayText: "wizard.flight.oneway.withHyphen.text",
  flightMultiCityText: "wizard.flight.multiCity.withHyphen.sentenceCase.text",
  submitButton: "wizard.submit.label",
  packageCheckboxInstructions: "wizard.mobile.subLabel.package",
  packageCheckboxErrorHeading: "wizard.package.type.combinations.heading",
  packageCheckboxErrorMessage: "wizard.package.type.combinations.message",
  flightCheckboxAddLabel: "wizard.package.addFlight.label",
  flightCheckboxRemoveLabel: "wizard.package.removeFlight.label",
  hotelCheckboxAddLabel: "wizard.package.addHotel.label",
  hotelCheckboxRemoveLabel: "wizard.package.removeHotel.label",
  carCheckboxAddLabel: "wizard.package.addCar.label",
  carCheckboxRemoveLabel: "wizard.package.removeCar.label",
  carDifferentPickOffDropOffLabel: "wizard.car.checkbox.rentSomewhereElse.text",
  announcementMap: {
    fh: "wizard.package.type.combinations.announcement.flightHotel",
    fhc: "wizard.package.type.combinations.announcement.flightHotelCar",
    fc: "wizard.package.type.combinations.announcement.flightCar",
    hc: "wizard.package.type.combinations.announcement.hotelCar",
  },
  error: {
    flightInvalidDepartureDate: "wizard.package.flight.warning.invalidDepartingDate.multicity",
    staySomewhereElseInvalidDestination: "wizard.destination.warning.invalidDestination.text",
  },
  packageAnd3PPSubtitle: "wizard.package.threepp.label",
  lobErrorHeadingsMap: {
    0: "",
    1: "wizard.package.invalid.header.selectAnother.text",
    2: "wizard.package.invalid.header.selectTwoOrMore.text",
    99: "wizard.package.invalid.header.selectAnother.text",
  },
  lobErrorHeadingsMapMultiLOB: {
    0: "",
    1: "wizard.package.invalid.header.selectAnother.text.v2",
    2: "wizard.package.invalid.header.selectTwoOrMore.text.v2",
    99: "wizard.package.invalid.header.selectAnother.text.v2",
  },
  lobErrorBodyMap: {
    0: "",
    1: "wizard.package.invalid.body.toStartBuilding.text",
    2: "wizard.package.invalid.body.toStartBuilding.text",
    99: "wizard.package.invalid.header.lobNotSupported.text",
  },
  lobErrorBodyMapMultiLOB: {
    0: "",
    1: "wizard.package.invalid.body.toStartBuilding.text.v2",
    2: "wizard.package.invalid.body.toStartBuilding.text.v2",
  },
  packageCheckboxChooseTwo: "wizard.package.sublob.description.chooseTwoOrMore",
  pacakgeCheckboxStartBuilding: "wizard.package.sublob.description.startBuildingTrip",
  packageValuePropSubtitle: "wizard.packages.valuePropSubtitle",
};

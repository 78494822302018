import { AnalyticsStore } from "bernie-plugin-mobx";
import { ClientLogger } from "bernie-client";
import { FAILED_ANALYTICS, TRACK_EVENT } from "config/systemEvents";

const LOGGER = ClientLogger.getLoggerWithIdentifier("FlexAnalyticsUtils");

const TIMEOUT = 500;

// Enum to describe actions for tracking purposes which follow the Blossom-Flex standards
export enum Action {
  CLICK = "click",
  IMPRESSION = "impression",
  BACK_BUTTON = "back-button",
  SCROLL = "scroll",
}

// Generic interface to ensure consistent Blossom-Flex tracking info when sending a track event
export interface FlexTrackingInfo {
  moduleName: string | null;
  action: Action | null;
  rfrr?: string;
  linkName?: string;
}

/**
 * Builds an appropriate and consistent rfrr id that follows the Blossom-Flex standards
 * @param {FlexTrackingInfo} flexTrackingInfo
 * @returns {string}
 */
export const buildFlexRfrrId = (flexTrackingInfo: FlexTrackingInfo): string => {
  if (!flexTrackingInfo?.action || !flexTrackingInfo.moduleName) {
    return "unknown";
  }

  const { rfrr, moduleName, action } = flexTrackingInfo;

  return rfrr ? `${moduleName}.${rfrr}.${action}` : `${moduleName}.${action}`;
};

/**
 * Generic function to send a track event leveraging the new @shared-ui/clickstream-analytics-context
 * With this function, we no longer need to prop drill AnalyticsStore.
 * @param useClickTracker: a reference of useClickTracker() from @shared-ui/clickstream-analytics-context
 * @param flexTrackingInfo
 * @param delay
 */
const sendClickstreamTrackEvent = (
  useClickTracker: (rfrr: string, linkName: string, delay?: boolean) => void,
  flexTrackingInfo: FlexTrackingInfo,
  delay: boolean
): void => {
  if (typeof window === "undefined" || !useClickTracker) {
    return;
  }

  const rfrrId = getRfrrIdAndLogToSplunk(flexTrackingInfo, delay);

  if (!rfrrId) {
    return;
  }

  useClickTracker(rfrrId, flexTrackingInfo.linkName || "RFRR Action Event", delay);
};

/**
 * Sends a track event immediately. Use this when clicking through to another page.
 */
export const sendImmediateClickstreamTrackEvent = (
  flexTrackingInfo: FlexTrackingInfo,
  useClickTracker: (rfrr: string, linkName: string, delay?: boolean) => void
) => {
  sendClickstreamTrackEvent(useClickTracker, flexTrackingInfo, false);
};

/**
 * Sends a track event after a small delay to optimize for UI thread.
 */
export const sendDelayedClickstreamTrackEvent = (
  flexTrackingInfo: FlexTrackingInfo,
  useClickTracker: (rfrr: string, linkName: string, delay?: boolean) => void
) => {
  sendClickstreamTrackEvent(useClickTracker, flexTrackingInfo, true);
};

/**
 * @deprecated Please use sendClickstreamTrackEvent instead
 * Generic function to send a track event that follows the Blossom-Flex standards
 */
const sendTrackEvent = (
  flexTrackingInfo: FlexTrackingInfo,
  analyticsStore: AnalyticsStore | undefined,
  trackImmediate: boolean,
  uisPrimeMicroMessages?: any
): void => {
  if (typeof window === "undefined" || !analyticsStore) {
    return;
  }

  const rfrrId = getRfrrIdAndLogToSplunk(flexTrackingInfo, trackImmediate);

  if (!rfrrId) {
    return;
  }

  analyticsStore.trackEvent(
    rfrrId,
    flexTrackingInfo.linkName || "RFRR Action Event",
    analyticsStore.omnitureData,
    null,
    trackImmediate,
    uisPrimeMicroMessages ? [uisPrimeMicroMessages] : uisPrimeMicroMessages
  );
};

/**
 * @deprecated Please use sendClickstreamTrackEvent instead
 * Sends a track event immediately. Use this when clicking through to another page.
 */
export const sendImmediateTrackEvent = (
  flexTrackingInfo: FlexTrackingInfo,
  analyticsStore: AnalyticsStore | undefined,
  uisPrimeMicroMessages?: any
): void => {
  sendTrackEvent(flexTrackingInfo, analyticsStore, true, uisPrimeMicroMessages);
};

/**
 * @deprecated Please use sendClickstreamTrackEvent instead
 * Sends a track event after a small delay to optimize for UI thread.
 */
export const sendDelayedTrackEvent = (
  flexTrackingInfo: FlexTrackingInfo,
  analyticsStore: AnalyticsStore | undefined,
  uisPrimeMicroMessages?: any
): void => {
  sendTrackEvent(flexTrackingInfo, analyticsStore, false, uisPrimeMicroMessages);
};

/**
 * Generic logging function to log a track event to Splunk
 * @param {FlexTrackingInfo} flexTrackingInfo
 */
export const logTrackEventToSplunk = (flexTrackingInfo: FlexTrackingInfo) => {
  LOGGER.logEvent(
    TRACK_EVENT,
    `Received track event: ${Object.keys(flexTrackingInfo)
      .map((key) => `${key}="${flexTrackingInfo[key as keyof FlexTrackingInfo]}"`)
      .join(" ")}`
  );
};

const getRfrrIdAndLogToSplunk = (flexTrackingInfo: FlexTrackingInfo, trackImmediate: boolean) => {
  const rfrrId = buildFlexRfrrId(flexTrackingInfo);

  if (!rfrrId) {
    LOGGER.logEvent(FAILED_ANALYTICS, "Missing moduleName or action to send track event", flexTrackingInfo);

    return null;
  }

  // free up the UI thread so it can move on to the intended click task
  if (trackImmediate) {
    logTrackEventToSplunk(flexTrackingInfo);
  } else {
    setTimeout(() => {
      logTrackEventToSplunk(flexTrackingInfo);
    }, TIMEOUT);
  }

  return rfrrId;
};

/**
 * Integrates Glassbox within the Blossom UI page.
 */
export const doGlassbox = () => {
  const scriptGlassBox = document.createElement("script");
  scriptGlassBox.src = "https://cdn.glassboxcdn.com/expedia/detector-dom.min.js";
  scriptGlassBox.id = "_cls_detector";
  scriptGlassBox.setAttribute(
    "data-clsconfig",
    "reportURI=https://report.bex.glassboxdigital.io/glassbox/reporting/076D5FEA-F74F-D2EA-CE0B-C715FFB17434/cls_report;maskingMode=whitelist;maskClassList=gb-whitelist;recordScrolls=true;recordMouseMoves=false;valueAutoMasking=true"
  );
  const firstScriptTag = document.querySelector("script");
  if (firstScriptTag) {
    firstScriptTag.appendChild(scriptGlassBox);
  } else {
    // Assuming the head is always present.
    document.querySelector("head")!.appendChild(scriptGlassBox);
  }
};

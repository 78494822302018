export enum PackageDealType {
  FLIGHT_HOTEL = "flight_hotel",
  HOTEL_CAR = "hotel_car",
}

export enum ViewType {
  IMAGE_TOP = "image-top",
  IMAGE_LEFT_EXPANDO = "image-left-expando",
}

export enum TruncateLines {
  TRUNCATE_ONE_LINE = "truncate",
  TRUNCATE_TWO_LINES = "truncate-2-lines",
}

export enum PillId {
  TRIP_LENGTH = "tripLength",
  STAR_RATING = "starRating",
  FLIGHT_CLASS = "flightClass",
}

export enum PackageDealMarkerSticker {
  FREE_FLIGHT = "PkgSavingsFreeFlight",
  FREE_CAR = "PkgSavingsFreeCar",
}

export enum SavingBadgeTextLocKeys {
  FLIGHT_SAVINGS = "package.card.badge.flightSavings",
  CAR_SAVINGS = "package.card.badge.carSavings",
}

import * as React from "react";
import { ClickTracker } from "bernie-analytics";
import { FlexClickTrackerProps } from "./typings";
import { Action, buildFlexRfrrId, FlexTrackingInfo, logTrackEventToSplunk } from "../FlexAnalyticsUtils";

/**
 * Generic implementation for Click Tracking for the Flex platform.
 * The 'moduleName' prop is required.
 *
 * Example use case:
 *    const TrackedButton = FlexClickTrackerProps(UitkPrimaryButton);
 *    <TrackedButton moduleName="hotels" rfrr="seeAllButton" />
 *
 * This will generate a rfrr ID of <rfrrNamespace>.hotels.seeAllButton.click
 *
 * NOTE: rfrrNamespace is defined in the controller (e.g. TG.LP)
 *
 */
const FlexClickTrackerFunc = (WrappedComponent: any) => {
  class FlexClickTracker extends React.Component<FlexClickTrackerProps, {}> {
    public render() {
      const TrackingComponent = ClickTracker(WrappedComponent);
      const { moduleName, rfrr, linkName, onClick, uisPrimeMicroMessages, ...allOtherProps } = this.props;

      const flexAnalyticsInfo: FlexTrackingInfo = {
        moduleName,
        rfrr,
        linkName,
        action: Action.CLICK,
      };

      const logToSplunk = (e: Event) => {
        if (typeof onClick === "function") {
          onClick(e);
        }

        logTrackEventToSplunk(flexAnalyticsInfo);
      };

      return (
        <TrackingComponent
          rfrr={buildFlexRfrrId(flexAnalyticsInfo)}
          linkName={linkName || "RFRR Action Link"}
          onClick={logToSplunk}
          trackImmediate
          uisPrimeMicroMessages={uisPrimeMicroMessages}
          {...allOtherProps}
        />
      );
    }
  }

  return FlexClickTracker;
};

export { FlexClickTrackerFunc as FlexClickTracker };

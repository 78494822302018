import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { NOOP_LOGGER } from "bernie-logger";
import {
  ExperienceContext,
  ExtendedContextStore,
  FlexViewModelResponse,
  UriContext,
} from "src/typings/flexFramework/FlexDefinitions";

export class UriContextStore extends Store {
  private uriContext?: UriContext;

  public constructor(state: SerializedData = {}) {
    super(state, NOOP_LOGGER);
  }

  public setup(res?: FlexViewModelResponse, experienceContext?: ExperienceContext): void {
    this.uriContext = experienceContext?.uriContext ?? res?.uriContext ?? this.uriContext;
  }

  public get(): UriContext {
    return (
      this.uriContext || {
        identifiers: {},
      }
    );
  }

  public getOrConvert(context: ExtendedContextStore): UriContext {
    const uric = this.get();
    return this.shouldConvert(context, uric) ? this.convert(context, uric) : uric;
  }

  private shouldConvert(context: ExtendedContextStore, uric: UriContext): boolean {
    if (!uric.identifiers && !uric.attributes) {
      return false; // Already V2 so don't convert
    }

    return context.pageId.startsWith("Homepage");
  }

  private convert(context: ExtendedContextStore, uric: UriContext): UriContext {
    if (context.pageId.startsWith("Homepage")) {
      return {
        funnelLocation: "ENTRY",
      };
    }

    return uric;
  }

  public hydrate(data: SerializedData = {}) {
    Object.assign(this, data);
  }

  public toJSON(): SerializedData {
    return {
      uriContext: this.uriContext,
    };
  }
}

export default UriContextStore;

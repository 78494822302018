export const wizardLobQueryParamKey = "pwaLob";

export const LOB_KEYS = {
  HOTEL_V2: "wizard-hotel-pwa-v2",
  HOTEL: "wizard-hotel-pwa",
  FLIGHT: "wizard-flight-pwa",
  PACKAGE: "wizard-package-pwa",
  CAR: "wizard-car-pwa",
  LX: "wizard-lx-pwa",
  EXT_LINK: "wizard-externalLinkTab-pwa",
  CRUISE: "wizard-cruise-pwa",
  VR: "wizard-vr-pwa",
  GT: "wizard-gt-pwa",
  THREEPP: "wizard-thirdPartyPackage-pwa",
};

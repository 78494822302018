import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { observable, action, makeObservable } from "mobx";
import { Logger } from "bernie-logger";
import { MapPlace } from "typings/map/eg-map";
import { Airport, FlightsLink } from "typings/microserviceModels/flights-flex-module";

export interface AirportMapPlace extends MapPlace {
  flight: FlightsLink;
}

export class FlightsStore extends Store {
  public flightsOnMap: AirportMapPlace[] = [];

  public constructor(state: SerializedData, logger: Logger) {
    super(state, logger);
    makeObservable(this, {
      flightsOnMap: observable,
      publishFlightsToMap: action,
    });
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  public publishFlightsToMap(flights: FlightsLink[]) {
    this.flightsOnMap = [];
    const singleOrigin = flights.every((flight) => flight.origin.code === flights[0].origin.code);
    const singleDestination = flights.every((flight) => flight.destination.code === flights[0].destination.code);
    if (singleOrigin) {
      this.flightsOnMap = this.buildSigleLocationAirportsList(flights, "origin");
    } else if (singleDestination) {
      this.flightsOnMap = this.buildSigleLocationAirportsList(flights, "destination");
    } else {
      this.flightsOnMap = this.buildMultiCityAirportsList(flights);
    }
  }

  private buildMultiCityAirportsList(flights: FlightsLink[]) {
    const flightsSet = new Set<string>();
    return flights.flatMap((flight, key) => this.mapMultiCityFlightsForMap(flight, key, flightsSet));
  }

  private buildSigleLocationAirportsList(flights: FlightsLink[], location: "origin" | "destination") {
    const reverseLocation = location === "origin" ? "destination" : "origin";
    const singleLocationAirports = flights
      .filter(
        (flight, key, array) => array.findIndex((f) => f[reverseLocation].code === flight[reverseLocation].code) === key
      )
      .map((flight, key) => FlightsStore.mapflightToAirportForMap(flight, key, flight[reverseLocation]));

    singleLocationAirports.push(
      FlightsStore.getSingleAirport(flights[0], singleLocationAirports.length, flights[0][location])
    );
    return singleLocationAirports;
  }

  private static getSingleAirport(flight: FlightsLink, key: number | string, airport: Airport): AirportMapPlace {
    return {
      id: key,
      latitude: airport.geoCoordinate.latitude,
      longitude: airport.geoCoordinate.longitude,
      icon: {
        type: "place",
      },
      flight,
    };
  }

  private static mapflightToAirportForMap(
    flight: FlightsLink,
    key: number | string,
    airport: Airport
  ): AirportMapPlace {
    return {
      id: key,
      latitude: airport.geoCoordinate.latitude,
      longitude: airport.geoCoordinate.longitude,
      imageUrl: flight.airCarrierImageUrl,
      icon: {
        type: "airport",
      },
      flight,
    };
  }

  private mapMultiCityFlightsForMap = (flight: FlightsLink, key: number, flightsSet: Set<string>) => {
    const multiCityAirports = [];
    if (!flightsSet.has(flight.origin.code)) {
      multiCityAirports.push(FlightsStore.getSingleAirport(flight, `${key}-1`, flight.origin));
      flightsSet.add(flight.origin.code);
    }
    if (!flightsSet.has(flight.destination.code)) {
      multiCityAirports.push(FlightsStore.mapflightToAirportForMap(flight, `${key}-2`, flight.destination));
      flightsSet.add(flight.destination.code);
    }
    return multiCityAirports;
  };
}

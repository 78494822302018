import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { Logger } from "bernie-logger";
import { StaticMapStore } from "./StaticMapStore";

export class ClientStaticMapStore extends Store implements StaticMapStore {
  public signedUrl: string;

  public signedPlaceHolderUrl: string;

  public signedAirportsUrl: string;

  public signedUrlNoPins: string;

  public constructor(state: SerializedData, logger: Logger) {
    super(state, logger);
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, {
      signedUrl: data.signedUrl,
      signedPlaceHolderUrl: data.signedPlaceHolderUrl,
      signedAirportsUrl: data.signedAirportsUrl,
      signedUrlNoPins: data.signedUrlNoPins,
    });
  }

  public generateGooglePlaceHolderMapImage = () => this.signedPlaceHolderUrl;

  public generateGoogleStaticMapSrcForHotels = () => this.signedUrl;

  public generateGoogleStaticMapSrcNoPins = () => this.signedUrl;

  public generateGoogleStaticMapSrcForAirports = () => this.signedAirportsUrl;
}

import { Store } from "bernie-plugin-mobx";
import { action, observable, makeObservable } from "mobx";
import { Logger } from "bernie-logger";
import { SerializedData } from "bernie-core";
import { ExtendedTemplateComponent } from "./typings";
import { ExperienceTemplate } from "experience-template-renderer-react";
import { Composition as FlexComposition } from "src/typings/flexFramework/FlexDefinitions";
import { flexToExtendedTemplateComponent } from "./flexToExtendedExperienceTemplateComponent";
import { DescendantDepth } from "./descendantDepth";
import { FlexComponent } from "src/typings/flexFramework/FlexComponent";

export class ExperienceTemplateStore extends Store {
  public id?: string;

  public version?: string;

  public components?: ExtendedTemplateComponent[];

  /* istanbul ignore next */
  public constructor(state: SerializedData = {}, logger: Logger) {
    super(state, logger);
    makeObservable(this, {
      components: observable,
      setup: action,
    });
  }

  /* istanbul ignore next */
  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  public setup(structureInput: FlexComposition | ExperienceTemplate) {
    const isFlexComposition = (structure: FlexComposition | ExperienceTemplate): structure is FlexComposition => {
      return !!(structure as FlexComposition).page;
    };

    const isExperienceTemplate = (structure: FlexComposition | ExperienceTemplate): structure is ExperienceTemplate => {
      return !isFlexComposition(structure);
    };

    if (isExperienceTemplate(structureInput)) {
      this.id = structureInput.id;
      this.version = structureInput.version;
      this.components = structureInput.components as ExtendedTemplateComponent[];
    } else {
      // isFlexComposition
      this.id = `${structureInput.templateId}`;
      this.version = `${structureInput.templateVersion}`;
      this.toExtendedTemplateComponents(structureInput.page.regionList);
    }
  }

  public toExtendedTemplateComponents(flexEntities: FlexComponent[]) {
    this.components = [];

    flexEntities.forEach((fe: FlexComponent) => {
      this.components?.push(flexToExtendedTemplateComponent(fe, DescendantDepth.ALL));
    });
  }
}

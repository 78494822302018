import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { Logger, NOOP_LOGGER } from "bernie-logger";
import { observable, makeObservable } from "mobx";

interface CookiesProps {
  cookie?: string;
}

/**
 * This store handles all cookies passed to the request headers,
 * currently just the wizard dates. If you wish to add more cookies,
 * you must change this store to be more generic (object instead of
 * individual keys for example)
 */
export class CookiesStore extends Store {
  public cookie = "";

  public constructor(state: CookiesProps = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
    makeObservable(this, {
      cookie: observable,
    });

    if (state.cookie) {
      this.cookie = state.cookie;
    }
  }

  /**
   * Gets the value of a given key in the cookie
   * @see https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
   * @param cookie {string} - Entire cookie string given by document or req
   * @param key {string} - Key of the value to get
   */
  public getCookie(key: string) {
    const value = this.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`);

    return value ? decodeURIComponent(value[2]) : null;
  }

  public hydrate(data: SerializedData): void {
    // Make sure that the cookies set server side also get set on client render
    Object.assign(this, data);
  }
}

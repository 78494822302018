import * as React from "react";
import { observer } from "mobx-react";

import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutGridItem, UitkLayoutConditionalGridSpan } from "uitk-react-layout-grid";
import { UitkIcon } from "uitk-react-icons";
import { UitkLink } from "uitk-react-link";
import {
  UitkMenu,
  UitkMenuTrigger,
  UitkMenuContainer,
  UitkMenuList,
  PositionType,
  MenuItemConfig,
} from "uitk-react-menu";

import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";
import { locKeys } from "./l10n";

export enum ClassCodes {
  ECONOMY = "e",
  PREMIUM_ECONOMY = "p",
  BUSINESS_CLASS = "b",
  FIRST_CLASS = "f",
}

const BiasedClassMap = new Map<string, string>([
  [ClassCodes.ECONOMY, "econonomy"],
  [ClassCodes.PREMIUM_ECONOMY, "premiumEconomy"],
  [ClassCodes.BUSINESS_CLASS, "business"],
  [ClassCodes.FIRST_CLASS, "firstClass"],
]);

export interface PreferredClassOption {
  label: string;
  classCode: ClassCodes;
}

export interface PreferredClassInputProps {
  colSpan?: UitkLayoutConditionalGridSpan;
  onSelectPreferredClassCode: (classCode: ClassCodes) => void;
  wizardState: ClientWizardStateStore;
}

export const PreferredClassInput: React.FC<PreferredClassInputProps> = observer(
  ({ wizardState, colSpan, onSelectPreferredClassCode }) => {
    const { formatText } = useLocalization();
    const { cabinClass = {} }: any = wizardState.flightWizardState.config;

    const biasedCabinCount = Array.from(BiasedClassMap.values()).filter((key) => {
      return !!cabinClass[key];
    }).length;

    const { flightClassCode } = wizardState.flightWizardState;

    const { ECONOMY, PREMIUM_ECONOMY, BUSINESS_CLASS, FIRST_CLASS } = ClassCodes;
    const linkRef: React.RefObject<HTMLButtonElement> = React.createRef();
    const preferredClassOptions: PreferredClassOption[] = [
      { label: formatText(locKeys.flightPreferredClassEconomy), classCode: ECONOMY },
      { label: formatText(locKeys.flightPreferredClassPremiumEconomy), classCode: PREMIUM_ECONOMY },
      { label: formatText(locKeys.flightPreferredClassBusiness), classCode: BUSINESS_CLASS },
      { label: formatText(locKeys.flightPreferredClassFirstClass), classCode: FIRST_CLASS },
    ].filter((e) => {
      const biasedCode = BiasedClassMap.get(e.classCode);

      return biasedCabinCount === 0 || (biasedCode && cabinClass[biasedCode]);
    });

    const preferredClassLabel = formatText(locKeys.flightPreferredClassLabel);
    const defaultClassCode = preferredClassOptions.find(({ classCode }) => classCode === flightClassCode);

    if (!defaultClassCode) {
      wizardState.flightWizardState.setFlightClass(preferredClassOptions[0].classCode);
    }

    const currentPreferredClass = defaultClassCode || preferredClassOptions[0];

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const handleMenuTriggerClick = (event: React.MouseEvent) => {
      event.preventDefault();
      setIsMenuOpen(!isMenuOpen);
    };

    const handlePreferredClassClick = (classCode: ClassCodes) => () => {
      onSelectPreferredClassCode(classCode);
      setIsMenuOpen(false);
      linkRef.current?.focus();
    };

    return (
      <UitkLayoutGridItem colSpan={colSpan}>
        <div>
          <UitkMenu id="preferred-class-input" isOpen={isMenuOpen} onTriggerClick={handleMenuTriggerClick}>
            <UitkMenuTrigger>
              <UitkLink id="preferred-class-input-trigger" data-testid="preferred-class-input-trigger">
                <button
                  type="button"
                  aria-label={`${preferredClassLabel} ${currentPreferredClass?.label}`}
                  ref={linkRef}
                >
                  {currentPreferredClass?.label}
                  <UitkIcon name="expand_more" />
                </button>
              </UitkLink>
            </UitkMenuTrigger>

            <UitkMenuContainer position={PositionType.LEFT}>
              <UitkMenuList
                items={preferredClassOptions.map(
                  ({ label, classCode }): MenuItemConfig => ({
                    attributes: {
                      role: "menuitem",
                      tabindex: 0,
                    },
                    href: "#",
                    className: "uitk-list-item",
                    label,
                    typeAnchor: true,
                    onClick: handlePreferredClassClick(classCode),
                  })
                )}
              />
            </UitkMenuContainer>
          </UitkMenu>
        </div>
      </UitkLayoutGridItem>
    );
  }
);

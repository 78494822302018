/* istanbul ignore file */
/*
While we are still using BlossomComponentsFactory to iterate through our data,
we need to occasionally create sub-trees of TemplateComponents to support  `shouldRender()` and `hasRenderableChildren()`.
`DescendantDepth` allows us to minimize data duplication in BlossomComponent.templateComponent
When we have migrated away from `<BlossomComponents/>` to `<ExperienceTemplateRenderer/>` we will no longer need to do this.
ExperienceTemplateStore will be the source of truth for the page and the entire tree and will be inspectable at any level
*/
export enum DescendantDepth {
  NONE = "NONE",
  ALL = "ALL",
}

export const getNextDepth = (descendantDepth: DescendantDepth): DescendantDepth =>
  descendantDepth === DescendantDepth.ALL ? DescendantDepth.ALL : DescendantDepth.NONE;

import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";

/**
 * returns an appropriate value for fmId given an ExtendedTemplateComponent. In a 100% Experience Template rendering environment, fmId is equivalent to id. If the provided templateComponent was created from a Flex Composition or Flex Template, the fmId value will be in the config object and not equivalent to id.
 * @param templateComponent ExtendedTemplateComponent
 *
 * @returns string
 */
export const getFmId = (templateComponent: ExtendedTemplateComponent): string => {
  const { metadata, config } = templateComponent;

  return config.fmId ? `${config.fmId}` : metadata.id;
};

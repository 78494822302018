import * as React from "react";
import { RangeCheckboxGroup } from "../Groupings/RangeCheckboxGroup/RangeCheckboxGroup";
import { SliderGroup } from "../Groupings/SliderGroup";
import { PropertyFiltersComponentGroupProps } from "../typings";

export const PriceFilterComponentMap = (props: PropertyFiltersComponentGroupProps) => {
  const { min, max, step } = props;

  if (min != null && max != null && step != null) {
    return <SliderGroup {...props} />;
  }

  return <RangeCheckboxGroup {...props} />;
};

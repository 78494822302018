import { ClientFactories, Store } from "bernie-plugin-mobx";
import { ClientLogger } from "bernie-client";
import { AdvertisingStore } from "bernie-plugin-ads-loader-script";

import { UIPageStore } from "./UIPageStore";
import { HotelsStore } from "./HotelsStore";
import { ClientStaticMapStore } from "./staticMap/ClientStaticMapStore";
import { DXStore } from "./DXStore";
import { NOOP_LOGGER } from "bernie-logger";
import { CookiesStore } from "stores/CookiesStore";
import { ClientMarkdownStore } from "./markdownHandler/ClientMarkdownStore";
import { ClientWizardStateStore } from "./wizard/ClientWizardStateStore";
import { ClientCompositionStore } from "src/stores/CompositionStore";
import { DestinationSuggestionsStore } from "stores/DestinationSuggestionsStore";
import { ClientFlexViewModelStore } from "stores/flexViewModel/ClientFlexViewModelStore";
import { ClientChatbotStore } from "./chatbotStore/ClientChatbotStore";
import { PackagePillStore } from "./PackagePillStore";
import { FlexModuleModelStore } from "./FlexModuleModelStore";
import { HotwireConfigStore } from "./HotwireConfigStore";
import { PropertyFiltersStore } from "./PropertyFiltersStore";
import { FlightsStore } from "stores/FlightsStore";
import { UriContextStore } from "./UriContextStore/UriContextStore";
import { ExperienceTemplateStore } from "stores/ExperienceTemplateStore";

export const stores: ClientFactories = {
  flexViewModel: (): Store =>
    new ClientFlexViewModelStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.viewmodel")),
  compositionStore: () =>
    new ClientCompositionStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.composition")),
  experienceTemplateStore: (): Store =>
    new ExperienceTemplateStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.experienceTemplate")),
  flexModuleModelStore: () =>
    new FlexModuleModelStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.flexModuleModel")),
  uiPage: (): Store => new UIPageStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.uiPage")),
  markdown: (): Store => new ClientMarkdownStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.markdown")),
  cookies: (): Store => new CookiesStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.cookies")),
  hotels: (): Store => {
    const logger = ClientLogger.getLoggerWithIdentifier("blossom.store.hotels");

    return new HotelsStore({}, logger);
  },
  staticMap: (): Store => new ClientStaticMapStore({}, NOOP_LOGGER),
  dx: (): Store => new DXStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.dx")),
  advertising: (): AdvertisingStore =>
    new AdvertisingStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.advertising")),
  destinationSuggestions: (): Store =>
    new DestinationSuggestionsStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.destinationSuggestions")),
  wizardState: (): Store =>
    new ClientWizardStateStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.wizardState")),
  propertyFilters: (): Store => {
    const logger = ClientLogger.getLoggerWithIdentifier("blossom.store.propertyFilters");

    return new PropertyFiltersStore({}, logger);
  },
  chatbot: (): Store => new ClientChatbotStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.chatbotStore")),
  packagePillStore: () =>
    new PackagePillStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.packagePillStore")),
  flightsStore: () => new FlightsStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.flightsStore")),
  hotwireConfig: (): Store =>
    new HotwireConfigStore({}, ClientLogger.getLoggerWithIdentifier("blossom.store.hotwireConfigStore")),
  uriContext: () => new UriContextStore(),
};

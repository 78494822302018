/* istanbul ignore file */
import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { Logger } from "bernie-logger";

import { HotwireApiConfig } from "components/utility/hotwire/HotwireApiUtil";
import { handleGuestCustomerId } from "components/utility/hotwire/HotwireGuestCustomerUtil";

export interface HotwireConfig {
  emailValidationService: HotwireApiConfig;
  emailSubscriptionService: HotwireApiConfig;
  customerStatsService: HotwireApiConfig;
  discountCodeService: HotwireApiConfig;
  customerIdentifyService: HotwireApiConfig;
}

export class HotwireConfigStore extends Store {
  public config: HotwireConfig;
  public logger: Logger;

  public constructor(serverConfig: SerializedData, logger: Logger) {
    super(serverConfig, logger);

    // On server, get required config and pass it to the client via hydration
    this.config = serverConfig?.app?.hotwire;

    // Expose logger so it can be used from outside
    this.logger = logger;
  }

  public hydrate(data: SerializedData): void {
    // On client, get config from hydrated data
    this.config = data.config;

    // After page is hydrated, identify Hotwire guest customer if needed
    handleGuestCustomerId(this.config.customerIdentifyService, this.logger);
  }

  public toJSON(ignoreAdditionalKeys?: string[] | string): SerializedData {
    return { config: this.config };
  }
}

import { codeSplit, Controller, FetchOptions, FetchPageOptions, StoreContainer } from "bernie-core";
import { AnalyticsStore, PageStore } from "bernie-plugin-mobx";
import { PageData, Request } from "bernie-http";
import { ExtendedContextStore, FlexViewModelResponse, PageType } from "typings/flexFramework/FlexDefinitions";
import { CompositionStore } from "src/stores/CompositionStore";
import { FlexViewModelStore } from "stores/flexViewModel/FlexViewModelStore";
import { StaticMapStore } from "stores/staticMap/StaticMapStore";
import { blossomCodeSplitter } from "src/components/flexFramework/CodeSplitter/BlossomCodeSplitter";
import { FlexModuleModelStore } from "src/stores/FlexModuleModelStore";
import { CookiesStore } from "stores/CookiesStore";
import { DXStore } from "stores/DXStore";
import { ServerChatbotStore } from "stores/chatbotStore/ServerChatbotStore";
import { PropertyFiltersStore } from "stores/PropertyFiltersStore";
import { UriContextStore } from "src/stores/UriContextStore/UriContextStore";
import { ExperienceTemplateStore } from "stores/ExperienceTemplateStore";
import { getBexApiContext } from "bernie-context";

interface FlexFetchPageOptions extends FetchPageOptions {
  context: ExtendedContextStore;
}

interface WindowWithState extends Window {
  __PLUGIN_STATE__: {
    controllers: {
      stores: {
        compositionStore: CompositionStore;
        experienceTemplateStore: ExperienceTemplateStore;
        context: ExtendedContextStore;
      };
    };
  };
}

declare let window: WindowWithState;

/**
 * Supports both composition (old) vs template rendering paths
 * Currently work in progress in order to work towards the BEXG Tech Strategy.
 * More info here: More info: https://confluence.expedia.biz/display/~mhaddock/Flex%3A+full+adoption+of+Tech+Strategy
 */
export class FlexController implements Controller {
  public pageId: string; // x-page-id is set in the middleware

  public path = "/*";

  public routeName = "blossom-FlexPage";

  public bundles = ["blossom-FlexPage"];

  public exact = false;

  public component = codeSplit(async () => {
    const routeComponent = import(/* webpackChunkName: "blossom-FlexPage" */ "views/FlexPage") as any;
    // Either template or composition is populated
    let composition;
    let experienceTemplateComponents;

    // Client-side only
    if (typeof window !== "undefined") {
      const serializedStores = window.__PLUGIN_STATE__.controllers.stores;
      composition = serializedStores.compositionStore?.composition;
      experienceTemplateComponents = serializedStores.experienceTemplateStore?.components;
    }

    await blossomCodeSplitter.loadRequiredCodeSplitBundles({ composition, experienceTemplateComponents });

    return routeComponent;
  });

  public async fetch(options: FetchOptions) {
    const { stores } = options;
    const flexViewModelStore = stores.get<FlexViewModelStore>("flexViewModel");
    const pageStore = stores.get<PageStore>("page");
    const contextStore = stores.get<ExtendedContextStore>("context");
    const mapStore = stores.get<StaticMapStore>("staticMap");
    const compositionStore = stores.get<CompositionStore>("compositionStore");
    const experienceTemplateStore = stores.get<ExperienceTemplateStore>("experienceTemplateStore");
    const flexModuleModelStore = stores.get<FlexModuleModelStore>("flexModuleModelStore");
    const analyticsStore = stores.get<AnalyticsStore>("analytics");
    const uriContextStore = stores.get<UriContextStore>("uriContext");

    // We get it from the middleware which hijacks the contextStore
    const flexBffResponse = contextStore.viewModelResponse;
    delete contextStore.viewModelResponse;

    // Core logic
    flexViewModelStore.setupFlexViewModelStore(flexBffResponse, pageStore, contextStore, analyticsStore);

    // Experience template path?
    const experienceContext = contextStore.experienceContext;
    const experienceMetadata = contextStore.experienceMetadata;
    const experienceTemplate = contextStore.experienceTemplate;
    // experienceComposition only on hybrid path
    const experienceComposition = contextStore.experienceComposition;

    if (experienceTemplate) {
      if (options.isServer) {
        // Init UisPrime analytics context
        // https://github.expedia.biz/Brand-Expedia/platform-analytics-prime-js/blob/master/src/platform-analytics.js#L79
        const payload = {
          applicationName: experienceMetadata?.pageName,
          // @ts-ignore
          traceId: contextStore.traceInfo?.["Trace-ID"],
          context: {
            pageIdentity: {
              pageIdentifier: experienceMetadata?.clickstreamPageNameDetailed,
            },
          },
        };
        analyticsStore.publishClientSidePayload([payload]);
      }
      experienceTemplateStore.setup(experienceTemplate);
    }

    // All paths
    compositionStore.setup(flexBffResponse, experienceComposition, contextStore, flexModuleModelStore, mapStore);

    uriContextStore.setup(flexBffResponse, experienceContext);

    // Extra stores to prepare
    await this.prepareAdditionalStores(options, flexBffResponse);
  }

  public async fetchPageData(options: FlexFetchPageOptions): Promise<PageData> {
    const bffResponse = options.context.viewModelResponse!;
    const experienceTemplate = options.context.experienceTemplate;
    const experienceComposition = options.context.experienceComposition;
    const experienceMetadata = options.context.experienceMetadata;
    const additionalScripts: string[] = blossomCodeSplitter.getRequiredWebpackChunkNames({
      composition: bffResponse?.composition,
      experienceTemplateComponents: experienceTemplate?.components,
    });

    const bexApiContext = getBexApiContext(options.context);

    const seoUtils = (await import(/* webpackChunkName: "SeoUtils" */ "utils/seo/SeoUtils")).default;
    let metadata: PageData;

    if (bffResponse && bffResponse.composition) {
      metadata = (await seoUtils.buildPageDataWithSeoMetaDataFromViewModel(bffResponse, bexApiContext)) || {
        title: bffResponse.composition.title,
      };
    } else if (experienceTemplate && experienceComposition) {
      metadata = {
        ...((await seoUtils.buildPageDataWithSeoMetaDataFromExperienceComposition(bffResponse, experienceTemplate)) || {
          title: "",
        }),
        ...experienceMetadata,
      };
    } else if (experienceTemplate) {
      metadata = experienceMetadata || {
        title: "",
      };
    } else {
      metadata = {
        title: "",
      };
    }

    return {
      ...metadata,
      assetsToIncludeFilter: (assetKey: string) => additionalScripts.includes(assetKey),
      pageId: options.context.pageId,
    };
  }

  // Non-essential stores to prepare
  private async prepareAdditionalStores(options: FetchOptions, flexBffResponse?: FlexViewModelResponse) {
    const { stores, request } = options;
    const chatbotStore = stores.get<ServerChatbotStore>("chatbot");
    const dxStore = stores.get<DXStore>("dx");
    const propertyFiltersStore = stores.get<PropertyFiltersStore>("propertyFilters");
    const cookiesStore = stores.get<CookiesStore>("cookies");
    const contextStore = stores.get<ExtendedContextStore>("context");

    //  Get cookie string for wizard
    cookiesStore.cookie = cookiesStore.cookie || request.headers.cookie || "";

    if (typeof chatbotStore.initChatbotStore === "function") {
      await chatbotStore.initChatbotStore(request, contextStore);
    }

    if (propertyFiltersStore && propertyFiltersStore.setRegion) {
      propertyFiltersStore.setRegion(contextStore?.searchContext?.location?.id || "");
    }

    dxStore.setupInitialJumplinks(flexBffResponse);
  }

  private pageTypesToStream: PageType[] = [
    "Storefront-Homepage",
    "Hotel-Information",
    "Travel-Guide-Hotels",
    "Destination-Travel-Guides",
    "Travel-Guide-Accommodation",
    "Travel-Guide-Filter-Hotels",
    "Travel-Guide-VacationRentals",
    "Alternative-Accommodations",
    "Hotel-Destinations",
    "Hotel-Filter-Destinations",
    "Vacation-Rental-Destinations",
    "Travel-Guide-Airlines",
    "Airline-Ond-Airport",
    "Travel-Guide-Flights",
    "Flight-Origin-City",
    "Flight-Destinations",
    "Flight-Ond-City",
    "Flight-Ond-Airport",
    "Car-Rental-Guide",
    "Car-Rental-Destinations",
    "Travel-Guide-CarRentalCompany",
    "Car-Rental-Guide-Car-Class",
    "Car-Rental-Guide-Suppliers",
    "Car-Rental-Destination-Suppliers",
    "Car-Rental-Supplier-Locations",
  ];

  /**
   * Happens before `fetch` is called, so the viewModelResponse is still in the context
   */
  public canStreamImmediately(request: Request, stores: StoreContainer): boolean {
    const contextStore = stores.get<ExtendedContextStore>("context");

    if (!contextStore) {
      return false;
    }

    const forceStreaming = contextStore.queryParams?.["streaming"] === "1";
    const isTemplateRendering = contextStore.queryParams?.["withTemplate"] === "1";

    const pageType = contextStore.viewModelResponse?.flexContext?.searchContext?.pageType;
    const experiments = contextStore.viewModelResponse?.flexContext?.experimentContext?.experiments;

    const isStreamingTnL = experiments?.["Blossom_Node_Streaming"] === 1;

    // Always stream given the query parameter
    if (forceStreaming) {
      return true;
    }

    // Do not use streaming on the template rendering path, because tokens will be missing
    // in the title and meta tags.
    if (isTemplateRendering) {
      return false;
    }

    if (contextStore.experienceContext) {
      return true;
    }

    // Stream some pageTypes 100% and others based on the experiment.
    return (pageType && this.pageTypesToStream.includes(pageType)) || isStreamingTnL;
  }
}

import { Store } from "bernie-plugin-mobx";
import { SerializedData } from "bernie-core";
import { Logger, NOOP_LOGGER } from "bernie-logger";
import { action, observable, makeObservable } from "mobx";

interface Notification {
  isVisible: boolean;
  icon: string;
  message: string;
}

// Length of time (ms) for a notification box to stay visible
const TIMEOUT = 4000;

/**
 * The goal of this store is to handle any UI Page effects such as showing notification/toast messages.
 * By calling showNotification from any component, you can show any notification with any message and UITK-React supported icon.
 * For example:
 *
 * showNotification("book now!", "favorite"); will display a notification box at the bottom of the page for TIMEOUT duration
 *
 */
export class UIPageStore extends Store {
  public notification: Notification;

  public notificationTimeout: any; // This is a timer object

  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
    makeObservable(this, {
      notification: observable,
      notificationTimeout: observable,
      showNotification: action,
      resetNotification: action,
    });
    this.resetNotification();
  }

  public hydrate(data: SerializedData): void {
    // Nothing to do here
  }

  public showNotification(message: string, icon: string): void {
    this.notification.isVisible = true;
    this.notification.icon = icon;
    this.notification.message = message;

    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }

    this.notificationTimeout = setTimeout(() => {
      this.resetNotification();
    }, TIMEOUT);
  }

  public resetNotification(): void {
    this.notification = {
      isVisible: false,
      icon: "favorite",
      message: "",
    };

    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }
  }
}
